import GenericSwitch from '@/components/shared/switchButtons/GenericSwitch/GenericSwitch.vue'
import TrackingService from '../../../../services/tracking.service'

export default {
  name: 'DataBillingStatusSwitch',
  components: {
    GenericSwitch,
  },
  data() {
    return {
      initialState: 'Achats facturés',
      states: [
        {
          label: 'Achats facturés',
          storeValue: 'billed',
        },
        {
          label: 'Achats commandés',
          storeValue: 'ordered',
        },
      ],
    }
  },
  methods: {
    billingStatusGetter() {
      return this.$store.getters.getDataBillingStatusSwitch
    },
    async billingStatusSetter(newDataBillingStatus) {
      TrackingService.trackEvent('click_toggle_data', { additional_data: newDataBillingStatus })
      await this.$store.dispatch('changeDataBillingStatus', newDataBillingStatus)
    },
  },
}
