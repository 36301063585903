import ApiConnectorService from './apiConnector.service'
import PrivateApiConnectorService from './privateApiConnector.service'
import AuthService from './auth.service'
import stringFormatterService from './stringFormatter.service'
import dateFormatterService from './dateFormatter.service'
import currencyFormatterService from './currencyFormatter.service'
import productsDistributionService from './marketSegments/productsDistribution.service'
import cumulativeCaService from './marketSegments/cumulativeCa.service'
import monthlyCaService from './marketSegments/monthlyCa.service'
import graphUtilityService from './graphUtility.service'
import productsCumulativeCaService from './marketSegments/productsCumulativeCa.service'
import supplierSummaryDataService from './marketSegments/supplierSummaryData.service'
import evolutionMarketShareService from './marketSegments/evolutionMarketShare.service'

const apiHost = window.env.PORTAIL_BACK_URL

const authService = new AuthService()
const apiConnectorService = new ApiConnectorService(apiHost)
const privateApiConnectorService = new PrivateApiConnectorService(apiHost)

export {
  graphUtilityService,
  apiConnectorService,
  privateApiConnectorService,
  authService,
  dateFormatterService,
  stringFormatterService,
  currencyFormatterService,
  productsDistributionService,
  cumulativeCaService,
  evolutionMarketShareService,
  monthlyCaService,
  productsCumulativeCaService,
  supplierSummaryDataService,
}
