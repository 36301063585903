export default {
  name: 'GenericPopin',
  props: {
    popinModel: {
      type: Boolean,
      default: false,
    },
    openButtonClass: {
      type: String,
      default: '',
    },
    contentWidth: {
      type: String,
      default: '60vw',
    },
    isButtonStyleHidden: {
      type: Boolean,
      default: true,
    },
    tooltipText: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      genericPopinModel: false,
    }
  },
  computed: {
    isTooltipEnabled() {
      return this.isNotEmpty(this.tooltipText)
    },
  },
  watch: {
    popinModel(newModelPropsValue) {
      this.genericPopinModel = newModelPropsValue
    },
  },
  methods: {
    isNotEmpty(value) {
      return !(!value || value.length === 0)
    },
    closePopin() {
      this.genericPopinModel = false
      this.$emit('close-popin')
    },
    openPopin() {
      this.genericPopinModel = true
      this.$emit('open-popin')
    },
  },
  beforeMount() {
    this.genericPopinModel = this.popinModel
  },
}
