import dateFormatterService from './../dateFormatter.service'
import cumulativeDataFormatter from '@/services/marketSegments/cumulativeDataFormatter.service'
import graphUtilityService from '@/services/graphUtility.service.js'
import tooltipService from './tooltip.service'
import currencyFormatterService from '@/services/currencyFormatter.service'

// This service provides functions to process API data for cumulative volume HighChart chart
const cumulativeCaService = {
  getUpdatedCumulativeVolumes(volumes, currentCampaign) {
    const currentStartDate = currentCampaign.getStartingDate()
    const currentEndDate = new Date()
    const previousStartDate = currentCampaign.getPrevious().getStartingDate()
    const previousEndDate = currentCampaign.getPrevious().getEndDate()
    return {
      current: cumulativeDataFormatter.sumVolumesDayByDay(volumes.current, currentStartDate, currentEndDate),
      previous: cumulativeDataFormatter.sumVolumesDayByDay(volumes.previous, previousStartDate, previousEndDate),
    }
  },
  getUpdatedCumulativeProductsVolumes(productsVolumes, currentCampaign) {
    const currentStartDate = currentCampaign.getStartingDate()
    const currentEndDate = new Date()
    const previousStartDate = currentCampaign.getPrevious().getStartingDate()
    const previousEndDate = currentCampaign.getPrevious().getEndDate()
    function formatCumulativeProducts(products, startDate, endDate) {
      const result = {}
      Object.keys(products).forEach(function (product) {
        result[product] = cumulativeDataFormatter.sumVolumesDayByDay(products[product], startDate, endDate)
      })
      return result
    }
    return {
      current: formatCumulativeProducts(productsVolumes.current, currentStartDate, currentEndDate),
      previous: formatCumulativeProducts(productsVolumes.previous, previousStartDate, previousEndDate),
    }
  },

  getLineChartData(supplierName, marketSegmentApiData, supplierApiData, campaigns, volumeUnit, visibleCampaigns) {
    const currentCampaign = dateFormatterService.formatCampaign(campaigns.current)
    const previousCampaign = dateFormatterService.formatCampaign(campaigns.previous)
    const unitName = currencyFormatterService.getFullUnitName(volumeUnit)
    const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME

    const marketData = {
      name: [unitName, appProviderName, currentCampaign].join(' '),
      visible: visibleCampaigns.includes(campaigns.current),
      data: cumulativeDataFormatter.formatDataChart(marketSegmentApiData.current, volumeUnit, false),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_PRIMARY_COLOR).full,
      campaign: 'current',
      perimeter: 'market',
      yAxis: 0,
      ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
    }
    const marketDataPrevious = {
      name: [unitName, appProviderName, previousCampaign].join(' '),
      visible: visibleCampaigns.includes(campaigns.previous),
      data: cumulativeDataFormatter.formatDataChart(marketSegmentApiData.previous, volumeUnit, true),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_PRIMARY_COLOR).full,
      campaign: 'previous',
      perimeter: 'market',
      yAxis: 0,
      ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
    }

    const supplierData = {
      name: unitName + ' ' + supplierName + ' ' + currentCampaign,
      visible: visibleCampaigns.includes(campaigns.current),
      data: cumulativeDataFormatter.formatDataChart(supplierApiData.current, volumeUnit, false),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).full,
      campaign: 'current',
      perimeter: 'supplier',
      yAxis: 1,
      ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
    }
    const supplierDataPrevious = {
      name: unitName + ' ' + supplierName + ' ' + previousCampaign,
      visible: visibleCampaigns.includes(campaigns.previous),
      data: cumulativeDataFormatter.formatDataChart(supplierApiData.previous, volumeUnit, true),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).full,
      campaign: 'previous',
      perimeter: 'supplier',
      yAxis: 1,
      ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
    }

    const computedYAxis = graphUtilityService.computeDoubleAxisBoundsFromSeries(
      [marketData.data, marketDataPrevious.data],
      [supplierData.data, supplierDataPrevious.data]
    )

    return {
      series: [marketData, marketDataPrevious, supplierData, supplierDataPrevious],
      yAxis: computedYAxis,
    }
  },

  getLineChartOptions(volumeUnit, supplierName) {
    const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME
    const yAxisTitleHtmlAppProvider = volumeUnit !== 'ha' ? `<b>CA ${appProviderName} (en €)</b>` : `<b>Surface couverte ${appProviderName} (en ha)</b>`
    const yAxisTitleHtmlSupplier = volumeUnit !== 'ha' ? '<b>CA ' + supplierName + ' (en €)</b>' : '<b>Surface couverte ' + supplierName + ' (en ha)</b>'
    return {
      chart: {
        type: 'spline',
        spacingRight: 0,
        spacingBottom: 30,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      series: [],
      yAxis: [
        {
          title: {
            text: yAxisTitleHtmlAppProvider,
          },
        },
        {
          title: {
            text: yAxisTitleHtmlSupplier,
          },
          align: 'right',
          opposite: true,
        },
      ],
      plotOptions: {
        spline: {
          marker: {
            symbol: 'circle',
            enabled: false,
          },
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%d/%m',
        },
        title: {
          text: '<b>Date</b>',
          reserveSpace: false,
          y: 10,
        },
      },
      tooltip: {
        split: false,
        shared: true,
        useHTML: true,
        borderRadius: 4,
        borderWidth: 0,
        shadow: true,
        crosshairs: true,
        formatter: function () {
          return tooltipService.getTooltipHTMLWithMarketShare('spline', this.points, this.x, volumeUnit)
        },
      },
    }
  },
}

export default cumulativeCaService
