import Vue from 'vue'
import Router from 'vue-router'
import MarketSegment from './views/MarketSegment/MarketSegment.vue'
import Contact from './views/Contact/Contact.vue'
import Login from './views/Login/Login.vue'
import ResetPassword from './views/ResetPassword/ResetPassword.vue'
import SupplierSummary from './views/SupplierSummary/SupplierSummary.vue'
import ErrorPage from './views/Error/Error.vue'
import { authService } from '@/services'
import UnavailableService from './views/UnavailableService/UnavailableService.vue'
import ConnectedLayout from './views/ConnectedLayout/ConnectedLayout.vue'
import TrackingService from './services/tracking.service'

if (process.env.NODE_ENV !== 'test') {
  Vue.use(Router)
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      meta: { requiresAuth: true },
      component: ConnectedLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: SupplierSummary,
        },
        {
          path: 'contact',
          name: 'contact',
          component: Contact,
        },
        {
          path: 'segment-de-marche/:marketSegment',
          name: 'market-segment',
          component: MarketSegment,
        },
      ],
    },
    {
      path: '/connexion',
      name: 'connexion',
      component: Login,
    },
    {
      path: '/reinitialisation-mot-de-passe',
      name: 'reset-password',
      component: ResetPassword,
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
    },
    {
      path: '/unavailable',
      name: 'unavailable',
      component: UnavailableService,
    },
    {
      path: '*',
      redirect: { name: 'error' },
    },
  ],
  from: null,
})

router.beforeEach(async (to, from, next) => {
  const auth = authService.isAuthenticated()
  TrackingService.setPersistentDataLayer({ page: { environment: window.env.ENV_NAME } })
  TrackingService.trackEvent('page_view', { path: to.path })
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth) {
      next({
        name: 'connexion',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
