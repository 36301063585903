const state = {
  userAnalyticsCookiesApproval: localStorage.getItem('userAnalyticsCookiesApproval')
    ? localStorage.getItem('userAnalyticsCookiesApproval') === 'true'
    : null,
}

const getters = {
  getUserAnalyticsCookiesPreferences: (state) => state.userAnalyticsCookiesApproval,
  areUserCookiesPreferencesValidated: (state) => state.userAnalyticsCookiesApproval !== null,
}

const mutations = {
  setUserAnalyticsCookiesPreferences(state, newUserChoice) {
    state.userAnalyticsCookiesApproval = newUserChoice
    localStorage.setItem('userAnalyticsCookiesApproval', String(newUserChoice))
  },
}

const actions = {
  saveUserAnalyticsCookiesPreferences({ commit }, userChoice) {
    commit('setUserAnalyticsCookiesPreferences', userChoice)
  },
}

export default {
  getters,
  actions,
  mutations,
  state,
}
