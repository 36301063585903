import { render, staticRenderFns } from "./CumulativeVolumeProduct.html?vue&type=template&id=a60ab600&scoped=true&"
import script from "./CumulativeVolumeProduct.js?vue&type=script&lang=js&"
export * from "./CumulativeVolumeProduct.js?vue&type=script&lang=js&"
import style0 from "./CumulativeVolumeProduct.scss?vue&type=style&index=0&id=a60ab600&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a60ab600",
  null
  
)

export default component.exports