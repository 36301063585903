import TopBasicbar from '@/components/shared/pageSections/headers/TopBasicbar/TopBasicbar.vue'
import { authService } from '../../services'

export default {
  name: 'ResetPassword',
  components: {
    TopBasicbar,
  },
  data() {
    return {
      email: '',
      success: false,
      error: null,
    }
  },
  computed: {
    isEmailNotEmpty() {
      this.error = null
      return this.isNotEmpty(this.email) || "L'email est obligatoire"
    },
    isEmailWellFormatted() {
      this.error = null
      return this.isEmailValid(this.email) || "Le format de l'email n'est pas valide"
    },
    submitDisabled() {
      return !this.isEmailValid(this.email) || !this.isNotEmpty(this.email)
    },
  },
  methods: {
    isNotEmpty(value) {
      return !(!value || value.length === 0)
    },
    isEmailValid(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value)
    },
    async resetPassword() {
      this.success = false
      const { email } = this

      try {
        await authService.resetPassword({ email })
        this.error = null
        this.success = true
      } catch (error) {
        if (error.response && error.response.status === 500) {
          this.error = 'Une erreur est survenue, veuillez contacter le support'
        } else this.error = 'Problème de connectivité, vérifiez votre couverture réseau'
      }
    },
  },
}
