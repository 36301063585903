import { privateApiConnectorService, cumulativeCaService, monthlyCaService } from '@/services'

const state = {
  isErrorLoadingSummary: false,
  dailyVolumes: {
    market: null,
    supplier: null,
    products: null,
  },
  monthlyVolumes: {
    market: null,
    supplier: null,
  },
  supplierSummary: {},
}

const getters = {
  getIsErrorLoadingSummary: (state) => {
    return state.isErrorLoadingSummary
  },
  getByBillingStatus: (state, globalGetters) => (dataFromState, defaultValue) => {
    const billingStatus = globalGetters.getApiFormatBillingStatus
    const currentDataFromState = dataFromState(state)
    const previousDataFromState = dataFromState(state)
    return {
      current: currentDataFromState
        ? currentDataFromState.current
          ? currentDataFromState.current[billingStatus] || defaultValue
          : defaultValue
        : defaultValue,
      previous: previousDataFromState
        ? previousDataFromState.previous
          ? previousDataFromState.previous[billingStatus] || defaultValue
          : defaultValue
        : defaultValue,
    }
  },
  getCumulativeVolumes: (state) => state.dailyVolumes,
  getMarketCumulativeVolumes: (state, globalGetters) => {
    const campaign = globalGetters.getCurrentCampaignObject
    const dailyVolumes = globalGetters.getByBillingStatus((state) => state.dailyVolumes.market, [])
    return cumulativeCaService.getUpdatedCumulativeVolumes(dailyVolumes, campaign)
  },
  getSupplierCumulativeVolumes: (state, globalGetters) => {
    const campaign = globalGetters.getCurrentCampaignObject
    const dailyVolumes = globalGetters.getByBillingStatus((state) => state.dailyVolumes.supplier, [])
    return cumulativeCaService.getUpdatedCumulativeVolumes(dailyVolumes, campaign)
  },
  getProductsCumulativeVolumes: (state, globalGetters) => {
    const campaign = globalGetters.getCurrentCampaignObject
    const dailyVolumes = globalGetters.getByBillingStatus((state) => state.dailyVolumes.products, [])
    return cumulativeCaService.getUpdatedCumulativeProductsVolumes(dailyVolumes, campaign)
  },
  getMarketMonthlyVolumes: (state, globalGetters) => {
    const dailyVolumes = globalGetters.getByBillingStatus((state) => state.dailyVolumes.market, [])
    return {
      current: monthlyCaService.groupValuesByMonth(dailyVolumes.current),
      previous: monthlyCaService.groupValuesByMonth(dailyVolumes.previous),
    }
  },
  getSupplierMonthlyVolumes: (state, globalGetters) => {
    const dailyVolumes = globalGetters.getByBillingStatus((state) => state.dailyVolumes.supplier, [])
    return {
      current: monthlyCaService.groupValuesByMonth(dailyVolumes.current),
      previous: monthlyCaService.groupValuesByMonth(dailyVolumes.previous),
    }
  },
  getActiveMarketsForSupplier: (state, globalGetters) => {
    if (Object.entries(state.supplierSummary).length === 0) {
      return []
    }
    const billingStatus = globalGetters.getApiFormatBillingStatus
    const marketSegmentations = state.supplierSummary[billingStatus] ? Object.keys(state.supplierSummary[billingStatus]) : []
    const markets = marketSegmentations.reduce((marketsAcc, segmentation) => {
      const summary = state.supplierSummary[billingStatus][segmentation] || {}
      const phytoSummary = summary.phyto ? summary.phyto : {}
      const seedsSummary = summary.seeds ? summary.seeds : {}
      const phytoMarkets = Object.values(phytoSummary).reduce((marketsAccumulator, marketGroupValues) => {
        return [...marketsAccumulator, ...Object.keys(marketGroupValues.subtotalValues)]
      }, [])
      const seedsMarkets = Object.values(seedsSummary).reduce((marketsAccumulator, marketGroupValues) => {
        return [...marketsAccumulator, ...Object.keys(marketGroupValues.subtotalValues)]
      }, [])
      return [...marketsAcc, ...phytoMarkets, ...seedsMarkets]
    }, [])

    return markets
  },
  getFullSupplierSummary: (state) => state.supplierSummary,
  getSupplierSummaryByMarketSegmentationSource: (state, getters) => (segmentationSource) => {
    const billingStatus = getters.getApiFormatBillingStatus
    return state.supplierSummary[billingStatus] ? state.supplierSummary[billingStatus][segmentationSource] : {}
  },
  getSeedsMarketGroupSupplierSummary: (state, getters) => {
    const billingStatus = getters.getApiFormatBillingStatus
    const seedsSummary = state.supplierSummary[billingStatus] ? state.supplierSummary[billingStatus].SCOR.seeds || {} : {}
    return Object.entries(seedsSummary).reduce((summaryAccumulator, marketGroup) => {
      const marketGroupName = marketGroup[0]
      const marketGroupValues = marketGroup[1]
      if (Object.keys(marketGroupValues.totalValues).length > 0) summaryAccumulator[marketGroupName] = marketGroupValues.totalValues
      return summaryAccumulator
    }, {})
  },
  getPhytoMarketGroupSupplierSummary: (state, getters) => {
    const billingStatus = getters.getApiFormatBillingStatus
    const phytoSummary = state.supplierSummary[billingStatus] ? state.supplierSummary[billingStatus].SCOR.phyto || {} : {}

    return Object.entries(phytoSummary).reduce((summaryAccumulator, marketGroup) => {
      const marketGroupName = marketGroup[0]
      const marketGroupValues = marketGroup[1]
      if (Object.keys(marketGroupValues.totalValues).length > 0) summaryAccumulator[marketGroupName] = marketGroupValues.totalValues
      return summaryAccumulator
    }, {})
  },
  getSeedsIndividualMarketSupplierSummary: (state, getters) => (segmentationSource) => {
    const seedsSummary = getters.getSupplierSummaryByMarketSegmentationSource(segmentationSource)
      ? getters.getSupplierSummaryByMarketSegmentationSource(segmentationSource).seeds || {}
      : {}
    return Object.values(seedsSummary).reduce((summaryAccumulator, marketGroupValues) => {
      return Object.assign(summaryAccumulator, marketGroupValues.subtotalValues)
    }, {})
  },
  getPhytoIndividualMarketSupplierSummary: (state, getters) => (segmentationSource) => {
    const phytoSummary = getters.getSupplierSummaryByMarketSegmentationSource(segmentationSource)
      ? getters.getSupplierSummaryByMarketSegmentationSource(segmentationSource).phyto || {}
      : {}
    return Object.values(phytoSummary).reduce((summaryAccumulator, marketGroupValues) => {
      return Object.assign(summaryAccumulator, marketGroupValues.subtotalValues)
    }, {})
  },
  getSeedsIndividualMarketSupplierSummaryFromGroup: (state, getters) => (marketGroup) => {
    const billingStatus = getters.getApiFormatBillingStatus
    const seedsGroupSummary = state.supplierSummary[billingStatus] ? state.supplierSummary[billingStatus].SCOR.seeds[marketGroup] || {} : {}
    return Object.assign({}, seedsGroupSummary.subtotalValues)
  },
  getPhytoIndividualMarketSupplierSummaryFromGroup: (state, getters) => (marketGroup) => {
    const billingStatus = getters.getApiFormatBillingStatus
    const phytoGroupSummary = state.supplierSummary[billingStatus] ? state.supplierSummary[billingStatus].SCOR.phyto[marketGroup] || {} : {}
    return Object.assign({}, phytoGroupSummary.subtotalValues)
  },
}

const mutations = {
  setDailyVolumes(state, dailyVolumes) {
    state.dailyVolumes = dailyVolumes
  },
  setMarketDailyVolumes(state, marketDailyVolumes) {
    state.dailyVolumes = {
      ...state.dailyVolumes,
      market: marketDailyVolumes,
    }
  },
  setSupplierDailyVolumes(state, supplierDailyVolumes) {
    state.dailyVolumes = {
      ...state.dailyVolumes,
      supplier: supplierDailyVolumes,
    }
  },
  setProductsDailyVolumes(state, productsDailyVolumes) {
    state.dailyVolumes = {
      ...state.dailyVolumes,
      products: productsDailyVolumes,
    }
  },
  setSupplierSummary(state, supplierSummary) {
    state.supplierSummary = supplierSummary
  },
  setIsErrorLoadingSummary(state, isError) {
    state.isErrorLoadingSummary = isError
  },
}

const actions = {
  async fetchSupplierSummary({ commit }, startingMonth) {
    try {
      const apiResultsSupplierSummary = await privateApiConnectorService.getSupplierSummary(startingMonth)
      commit('setSupplierSummary', apiResultsSupplierSummary)
    } catch (error) {
      if (error.response.status === 500) {
        const apiResultsSupplierSummary = {}
        commit('setIsErrorLoadingSummary', true)
        commit('setSupplierSummary', apiResultsSupplierSummary)
      }
    }
  },
  async updateVolumeData({ rootGetters, commit }, segmentId) {
    const selectedCampaign = rootGetters.getCurrentCampaignObject
    const currentCampaignStartingDate = selectedCampaign.getStartingDate()
    const currentCampaignEndDate = selectedCampaign.getEndDate()
    const previousCampaignStartingDate = selectedCampaign.getPrevious().getStartingDate()
    const previousCampaignEndDate = selectedCampaign.getPrevious().getEndDate()

    const apiResultsMarketCACurrentPromise = privateApiConnectorService.getDailyVolumesByMarket(
      segmentId,
      currentCampaignStartingDate,
      currentCampaignEndDate
    )

    const apiResultsMarketCAPreviousPromise = privateApiConnectorService.getDailyVolumesByMarket(
      segmentId,
      previousCampaignStartingDate,
      previousCampaignEndDate
    )

    const apiResultsProductsCACurrentPromise = privateApiConnectorService.getProductsDailyCaForSegment(
      segmentId,
      currentCampaignStartingDate,
      currentCampaignEndDate
    )

    const apiResultsProductsCAPreviousPromise = privateApiConnectorService.getProductsDailyCaForSegment(
      segmentId,
      previousCampaignStartingDate,
      previousCampaignEndDate
    )

    const [apiResultsMarketCACurrent, apiResultsMarketCAPrevious, apiResultsProductsCACurrent, apiResultsProductsCAPrevious] =
      await Promise.all([
        apiResultsMarketCACurrentPromise,
        apiResultsMarketCAPreviousPromise,
        apiResultsProductsCACurrentPromise,
        apiResultsProductsCAPreviousPromise,
      ])

    const marketSegmentData = {
      current: apiResultsMarketCACurrent.market || [],
      previous: apiResultsMarketCAPrevious.market || [],
    }

    const supplierData = {
      current: apiResultsMarketCACurrent.supplier || [],
      previous: apiResultsMarketCAPrevious.supplier || [],
    }

    const supplierProductsData = {
      current: apiResultsProductsCACurrent.products || {},
      previous: apiResultsProductsCAPrevious.products || {},
    }

    commit('setMarketDailyVolumes', marketSegmentData)
    commit('setSupplierDailyVolumes', supplierData)
    commit('setProductsDailyVolumes', supplierProductsData)
  },
}

export default {
  getters,
  actions,
  mutations,
  state,
}
