import dateFormatterService from './../dateFormatter.service'
import tooltipService from './tooltip.service'
import graphUtilityService from '@/services/graphUtility.service.js'
import currencyFormatterService from '@/services/currencyFormatter.service'

const monthlyCaService = {
  groupValuesByMonth(dailyVolumes) {
    const groupedByMonth = dailyVolumes.reduce((acc, currentValue) => {
      const accCopy = Object.assign({}, acc)
      const date = currentValue.day.slice(0, 7)
      const previousValue = acc[date] || { ca: 0, ha: 0, dose: 0 }
      accCopy[date] = {
        ca: previousValue.ca + currentValue.ca,
        ha: previousValue.ha + currentValue.ha,
        dose: previousValue.dose + currentValue.dose,
      }
      return accCopy
    }, {})
    return Object.keys(groupedByMonth).map((yearMonth) => {
      const date = new Date(yearMonth + '-01')
      return {
        totalCA: groupedByMonth[yearMonth].ca,
        totalHA: groupedByMonth[yearMonth].ha,
        totalDose: groupedByMonth[yearMonth].dose,
        year: date.getFullYear(),
        month: date.getMonth() + 1,
      }
    })
  },

  formatData(data, isPrevious, volumeUnit) {
    const volumeMeasurementUnit = currencyFormatterService.getMonthlyVolumeColumnName(volumeUnit)
    if (data) {
      return data
        .map((record) => {
          const date = new Date(Date.UTC(record.year, record.month - 1))
          if (isPrevious) {
            date.setFullYear(date.getFullYear() + 1)
          }
          return {
            x: date.getTime(),
            y: record[volumeMeasurementUnit],
          }
        })
        .sort((rec1, rec2) => (rec1.x < rec2.x ? -1 : 1))
    }
    return []
  },

  getColumnChartData(supplierName, marketSegmentApiData, supplierApiData, campaigns, volumeUnit, visibleCampaigns) {
    const currentCampaign = dateFormatterService.formatCampaign(campaigns.current)
    const previousCampaign = dateFormatterService.formatCampaign(campaigns.previous)

    const unitName = currencyFormatterService.getFullUnitName(volumeUnit)
    const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME

    const marketData = {
      name: [unitName, appProviderName, currentCampaign].join(' '),
      visible: visibleCampaigns.includes(campaigns.current),
      data: this.formatData(marketSegmentApiData.current, false, volumeUnit),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_PRIMARY_COLOR).full,
      borderColor: graphUtilityService.formatColor(graphUtilityService.THEME_PRIMARY_COLOR).full,
      campaign: 'current',
      perimeter: 'market',
      yAxis: 0,
      ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
    }

    const marketDataPrevious = {
      name: [unitName, appProviderName, previousCampaign].join(' '),
      visible: visibleCampaigns.includes(campaigns.previous),
      data: this.formatData(marketSegmentApiData.previous, true, volumeUnit),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_PRIMARY_COLOR).opaque,
      borderColor: graphUtilityService.formatColor(graphUtilityService.THEME_PRIMARY_COLOR).full,
      campaign: 'previous',
      perimeter: 'market',
      yAxis: 0,
      ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
    }

    const supplierData = {
      name: unitName + ' ' + supplierName + ' ' + currentCampaign,
      visible: visibleCampaigns.includes(campaigns.current),
      data: this.formatData(supplierApiData.current, false, volumeUnit),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).full,
      borderColor: graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).full,
      campaign: 'current',
      perimeter: 'supplier',
      yAxis: 1,
      ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
    }

    const supplierDataPrevious = {
      name: unitName + ' ' + supplierName + ' ' + previousCampaign,
      visible: visibleCampaigns.includes(campaigns.previous),
      data: this.formatData(supplierApiData.previous, true, volumeUnit),
      color: graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).opaque,
      borderColor: graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).full,
      campaign: 'previous',
      perimeter: 'supplier',
      yAxis: 1,
      ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
    }

    const computedYAxis = graphUtilityService.computeDoubleAxisBoundsFromSeries(
      [marketData.data, marketDataPrevious.data],
      [supplierData.data, supplierDataPrevious.data]
    )

    return {
      series: [marketDataPrevious, marketData, supplierDataPrevious, supplierData],
      yAxis: computedYAxis,
    }
  },

  getColumnChartOptions(volumeUnit, supplierName) {
    const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME
    const yAxisTitleHtmlAppProvider = volumeUnit !== 'ha' ? `<b>CA ${appProviderName} (en €)</b>` : `<b>Surface couverte ${appProviderName} (en ha)</b>`
    const yAxisTitleHtmlSupplier = volumeUnit !== 'ha' ? '<b>CA ' + supplierName + ' (en €)</b>' : '<b>Surface couverte ' + supplierName + ' (en ha)</b>'

    return {
      chart: {
        type: 'column',
        alignThresholds: true,
        alignTicks: true,
        spacingRight: 0,
        spacingBottom: 30,
      },
      time: {
        useUTC: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          grouping: true,
          shadow: false,
          pointIntervalUnit: 'month',
          pointPadding: 0,
          groupPadding: 0.1,
          turboThreshold: 0,
          maxPointWidth: 10,
        },
      },
      series: [],
      yAxis: [
        {
          title: {
            text: yAxisTitleHtmlAppProvider,
          },
        },
        {
          title: {
            text: yAxisTitleHtmlSupplier,
          },
          opposite: true,
        },
      ],
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%b}',
        },
        title: {
          text: '<b>Mois</b>',
          reserveSpace: false,
          y: 10,
        },
        gridZIndex: 0,
        tickInterval: 30 * 24 * 3600 * 1000,
        tickLength: 0,
      },
      tooltip: {
        split: false,
        shared: true,
        useHTML: true,
        borderRadius: 4,
        borderWidth: 0,
        followPointer: true,
        shadow: true,
        formatter: function () {
          return tooltipService.getTooltipHTMLWithMarketShare('column', this.points, this.x, volumeUnit, '%m/%y')
        },
      },
    }
  },
  getPlotBandsOptions(campaignStartingYear, campaignStartingMonth) {
    // Note: pointer-events for highcharts-grid-line and highcharts-point are disabled to make this work properly
    const NUMBER_OF_MONTHS_IN_CAMPAIGN = 12
    const DAY_LENGTH = 24 * 60 * 60 * 1000
    const plotBandHoverMask = 'rgba(255, 255, 255, .8)'
    return Array.apply(null, Array(NUMBER_OF_MONTHS_IN_CAMPAIGN)).map((_, monthIndex) => {
      return {
        color: 'transparent',
        zIndex: 5,
        from: Date.UTC(Number(campaignStartingYear), Number(campaignStartingMonth) + monthIndex - 1) - DAY_LENGTH * 15,
        to: Date.UTC(Number(campaignStartingYear), Number(campaignStartingMonth) + monthIndex - 1) + DAY_LENGTH * 15,
        events: {
          mouseover: function () {
            const allPlotBands = this.axis.plotLinesAndBands
            allPlotBands.map((band) => {
              band.svgElem.attr('fill', plotBandHoverMask)
            })
            this.svgElem.attr('fill', 'transparent')
          },
          mouseout: function () {
            const allPlotBands = this.axis.plotLinesAndBands
            allPlotBands.map((band) => {
              band.svgElem.attr('fill', band.options.color)
            })
          },
        },
      }
    })
  },
}

export default monthlyCaService
