import { productsDistributionService } from '@/services/index'
import ChartContainer from '@/components/shared/charts/ChartContainer/ChartContainer.vue'
import dateFormatterService from '../../../../services/dateFormatter.service'
import format from 'date-fns/format'

export default {
  name: 'ProductsDistribution',
  components: {
    ChartContainer,
  },
  props: ['chartId', 'legendChartIds', 'isFullscreenLegendEnabled', 'isFullscreenLegendButtonsAreaEnabled'],
  data() {
    return {
      productDistributionByCa: {
        chartOptions: {},
        chartData: {},
      },
    }
  },
  computed: {
    volumeUnit() {
      return this.$store.getters.getVolumeUnit
    },
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns
    },
    chartOptions() {
      const campaignStartDate = this.$store.getters.getCurrentCampaignObject.getStartingDate()
      const lastUpdateTime = dateFormatterService.formatDate(this.$store.getters.getLastUpdateTime)
      const startCampaign = format(campaignStartDate, 'DD/MM/YYYY')
      return productsDistributionService.getBarChartOptions(startCampaign, lastUpdateTime)
    },
    chartData() {
      const campaigns = {
        current: this.$store.getters.getCurrentCampaignObject.toString(),
        previous: this.$store.getters.getCurrentCampaignObject.getPrevious().toString(),
      }
      const marketCumulativeVolumes = this.$store.getters.getMarketCumulativeVolumes
      const productsCumulativeVolumes = this.$store.getters.getProductsCumulativeVolumes
      return productsDistributionService.getBarChartData(
        marketCumulativeVolumes,
        productsCumulativeVolumes,
        campaigns,
        this.volumeUnit,
        this.enabledCampaigns
      )
    },
  },
}
