import GenericPopin from '@/components/shared/popins/GenericPopin/GenericPopin.vue'
import CookiesForm from '@/components/shared/popins/CookiesForm/CookiesForm.vue'

export default {
  name: 'CookiesBanner',
  data() {
    return {
      bannerModel: this.isBannerEnabled,
    }
  },
  components: {
    GenericPopin,
    CookiesForm,
  },
  props: ['isBannerEnabled'],
  computed: {
    enableCloseOnOutsideClick() {
      return this.$store.state.isStoreInitiated
    },
  },
  watch: {
    bannerModel(newModelValue) {
      const bannerWasClosed = !newModelValue
      if (bannerWasClosed) {
        this.validateCookiesPreferences() // means this will execute when opening the settings popin from the banner
      }
    },
  },
  methods: {
    validateCookiesPreferences() {
      this.$store.dispatch('saveUserAnalyticsCookiesPreferences', true)
    },
    closeBanner() {
      this.bannerModel = false
    },
  },
}
