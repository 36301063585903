export default {
  name: 'GenericCheckboxSelectors',
  props: ['label', 'states', 'storeStateSetter'],
  data() {
    return {
      defaultCheckboxColor: getComputedStyle(document.documentElement).getPropertyValue('--v-greyDark-base'),
    }
  },
  methods: {
    onCheckboxSelection(checkboxKey) {
      this.storeStateSetter(checkboxKey)
    },
    setCheckboxesColor() {
      Object.keys(this.states).forEach((checkboxKey) => {
        const checkbox = this.$refs[checkboxKey]
        const properties = this.states[checkboxKey]
        if (checkbox && !properties.checked) {
          checkbox[0].$el.querySelector('.v-icon').style = 'color: ' + properties.color + '; caret-color: ' + properties.color
        }
      })
    },
  },
  mounted() {
    this.setCheckboxesColor()
  },
  updated() {
    this.setCheckboxesColor()
  },
}
