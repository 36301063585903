import { authService, stringFormatterService } from '../../../../../services/index'
import GenericPopin from '@/components/shared/popins/GenericPopin/GenericPopin.vue'
import CookiesForm from '@/components/shared/popins/CookiesForm/CookiesForm.vue'
import ChangeSupplier from '@/components/shared/popins/ChangeSupplier/ChangeSupplier.vue'
import Contact from '@/views/Contact/Contact.vue'
import TrackingService from '../../../../../services/tracking.service'

export default {
  name: 'TopToolbar',
  components: {
    GenericPopin,
    CookiesForm,
    Contact,
    ChangeSupplier,
  },
  data: () => ({
    marketSegmentsTopToolbar: [],
    search: null,
    hideNoData: true,
    userSearchInput: null,
  }),
  watch: {
    search(val) {
      this.userSearchInput = val
      if (val && val.length > 2) {
        this.marketSegmentsTopToolbar = this.marketSegments
        this.hideNoData = false
      } else {
        this.marketSegmentsTopToolbar = []
        this.$refs.marketSegmentsAutocomplete.cachedItems = []
        this.hideNoData = true
      }
    },
  },
  computed: {
    appProviderColor() {
      return window.env.APP_PROVIDER_COLOR
    },
    isUserAdmin() {
      return this.$store.getters.isAdmin
    },
    selectedMarketSegment: {
      get() {
        return this.$store.getters.getSelectedMarketSegmentName
      },
      set(newSelectedSegmentName) {
        if (newSelectedSegmentName === null) {
          return
        }
        if (this.$refs.marketSegmentsAutocomplete) {
          this.$refs.marketSegmentsAutocomplete.blur()
        }
        const slugifiedMarketSegment = stringFormatterService.slugify(newSelectedSegmentName)
        TrackingService.trackEvent('global_header_click_search_result', { additional_data: slugifiedMarketSegment })
        this.$router.replace({
          name: 'market-segment',
          params: { marketSegment: slugifiedMarketSegment },
        })
        this.marketSegmentsTopToolbar = []
        this.search = ''
      },
    },
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated
    },
    marketSegments() {
      return this.$store.getters.getActiveMarketsForSupplier
    },
  },
  methods: {
    marketSegmentRoute() {
      if (this.selectedMarketSegment != null) {
        const slugifiedMarket = stringFormatterService.slugify(this.selectedMarketSegment)
        return '/segment-de-marche/' + slugifiedMarket
      }
    },
    closeDropdownContact() {
      this.$refs.dropdownMenuRef.isActive = false
    },
    closeDropdownCookies() {
      this.$refs.dropdownMenuRef.isActive = false
    },
    closeDropdownChangeSupplier() {
      this.$refs.dropdownMenuRef.isActive = false
    },
    logout() {
      authService.logout()
      TrackingService.trackEvent('global_header_click_disconnect')
    },
    clickTopLogo() {
      TrackingService.trackEvent('global_header_click_title')
    },
    clickCookies() {
      TrackingService.trackEvent('global_header_click_cookies')
    },
    clickChangeSupplier() {
      TrackingService.trackEvent('global_header_click_change_supplier')
    },
  },
}
