const supplierSummaryDataService = {
  calculateEvolution(from, to) {
    if (from) return ((to - from) / from) * 100
    else return null
  },

  calculateMarketShare(part, whole) {
    return (part / whole) * 100
  },

  getSingleMarketIndicatorsFromVolumes(volumeValues, market) {
    const currentVolumeValues = volumeValues.current || {}
    const previousVolumeValues = volumeValues.previous || {}

    const formattedValues = {
      market_segment: market,
      supplier_current_total_value: currentVolumeValues.supplier_total_value || 0,
      market_current_total_value: currentVolumeValues.market_total_value || 0,
      supplier_previous_truncated_value: previousVolumeValues.supplier_total_value || 0,
      market_previous_truncated_value: previousVolumeValues.market_total_value || 0,
    }

    const currentMarketShare = this.calculateMarketShare(
      formattedValues.supplier_current_total_value,
      formattedValues.market_current_total_value
    )
    const previousMarketShare = this.calculateMarketShare(
      formattedValues.supplier_previous_truncated_value,
      formattedValues.market_previous_truncated_value
    )
    return {
      market: formattedValues.market_segment,
      marketShare: currentMarketShare,
      marketShareEvolution: currentMarketShare - previousMarketShare,
      supplierCa: formattedValues.supplier_current_total_value,
      supplierCaEvolution: this.calculateEvolution(
        formattedValues.supplier_previous_truncated_value,
        formattedValues.supplier_current_total_value
      ),
      marketCa: formattedValues.market_current_total_value,
      marketCaEvolution: this.calculateEvolution(
        formattedValues.market_previous_truncated_value,
        formattedValues.market_current_total_value
      ),
    }
  },

  getGroupIndicatorsFromVolumes(volumeValues, market) {
    const currentVolumeValues = volumeValues.current || {}
    const previousVolumeValues = volumeValues.previous || {}

    const formattedValues = {
      market_group: market,
      supplier_current_total_value: currentVolumeValues.supplier_group_total_value || 0,
      group_current_total_value: currentVolumeValues.group_total_value || 0,
      supplier_previous_truncated_value: previousVolumeValues.supplier_group_total_value || 0,
      group_previous_truncated_value: previousVolumeValues.group_total_value || 0,
    }

    const currentMarketShare = this.calculateMarketShare(
      formattedValues.supplier_current_total_value,
      formattedValues.group_current_total_value
    )
    const previousMarketShare = this.calculateMarketShare(
      formattedValues.supplier_previous_truncated_value,
      formattedValues.group_previous_truncated_value
    )
    return {
      market: formattedValues.market_group,
      marketShare: currentMarketShare,
      marketShareEvolution: currentMarketShare - previousMarketShare,
      supplierCa: formattedValues.supplier_current_total_value,
      supplierCaEvolution: this.calculateEvolution(
        formattedValues.supplier_previous_truncated_value,
        formattedValues.supplier_current_total_value
      ),
      marketCa: formattedValues.group_current_total_value,
      marketCaEvolution: this.calculateEvolution(formattedValues.group_previous_truncated_value, formattedValues.group_current_total_value),
    }
  },

  getIndividualMarketSupplierSummaryByVolumeType(summary, volumeUnit) {
    return Object.keys(summary).map((market) => {
      const volumes = summary[market][volumeUnit] || {}
      return supplierSummaryDataService.getSingleMarketIndicatorsFromVolumes(volumes, market)
    })
  },

  getMarketGroupSupplierSummaryByVolumeType(summary, volumeUnit) {
    return Object.keys(summary).map((market) => {
      const volumes = summary[market][volumeUnit] || {}
      return supplierSummaryDataService.getGroupIndicatorsFromVolumes(volumes, market)
    })
  },
}

export default supplierSummaryDataService
