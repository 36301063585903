export default {
  name: 'GenericSwitch',
  props: ['label', 'states', 'storeStateGetter', 'storeStateSetter'],
  methods: {
    onSwitch(newState) {
      this.storeStateSetter(newState.storeValue)
    },
    isSelected(state) {
      return state.storeValue === this.currentStoreValue
    },
  },
  computed: {
    currentStoreValue() {
      return this.storeStateGetter()
    },
  },
}
