import { currencyFormatterService } from '@/services/index'

export default {
  name: 'BigNumberIndicator',
  props: ['value', 'evolution', 'volumeUnit'],
  computed: {
    hasLabelSlot() {
      return !!this.$slots.default // only has one in this case so we use default
    },
    isEvolutionGrowing() {
      return currencyFormatterService.isEvolutionGrowing(this.evolution)
    },
    isEvolutionDecreasing() {
      return currencyFormatterService.isEvolutionDecreasing(this.evolution)
    },
  },
  methods: {
    formatValue(value) {
      return this.volumeUnit === '%'
        ? currencyFormatterService.formatMarketShare(value)
        : currencyFormatterService.formatValue(value, this.volumeUnit)
    },
    formatEvolution(evolution) {
      return this.volumeUnit === '%'
        ? currencyFormatterService.formatMarketShareEvolution(evolution)
        : currencyFormatterService.formatEvolution(evolution)
    },
  },
}
