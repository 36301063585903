import Vue from 'vue'

const LOCAL_STORAGE_ENABLED_SERIES_KEY = 'enabledSeries'

const state = {
  charts: {},
  enabledSeries: JSON.parse(localStorage.getItem(LOCAL_STORAGE_ENABLED_SERIES_KEY)) || {},
  selectedFullscreenChartId: null,
}

const getters = {
  getEnabledSeries: (state) => state.enabledSeries,
  getCharts: (state) => state.charts,
  getFullscreenChartId: (state) => state.selectedFullscreenChartId,
  getFullNamePerimeter: (state, globalGetters) => (perimeter) => {
    const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME
    if (perimeter === 'market') return globalGetters.getVolumeUnitName + ' ' + appProviderName
    else if (perimeter === 'supplier') return globalGetters.getVolumeUnitName + ' ' + globalGetters.getSupplierName
    else if (perimeter === 'marketShare') return 'PDM ' + globalGetters.getSupplierName
    else return perimeter
  },
}

const mutations = {
  disableSerieByPerimeter(state, { chartId, perimeter }) {
    if (state.enabledSeries[chartId]) {
      Vue.set(state.enabledSeries, chartId, Object.assign({}, state.enabledSeries[chartId], { [perimeter]: false }))
    } else {
      Vue.set(state.enabledSeries, chartId, { [perimeter]: false })
    }

    localStorage.setItem(LOCAL_STORAGE_ENABLED_SERIES_KEY, JSON.stringify(state.enabledSeries))
  },
  enableSerieByPerimeter(state, { chartId, perimeter }) {
    if (state.enabledSeries[chartId]) {
      Vue.set(state.enabledSeries, chartId, Object.assign({}, state.enabledSeries[chartId], { [perimeter]: true }))
    } else {
      Vue.set(state.enabledSeries, chartId, { [perimeter]: true })
    }
    localStorage.setItem(LOCAL_STORAGE_ENABLED_SERIES_KEY, JSON.stringify(state.enabledSeries))
  },
  upsertChart(state, chart) {
    state.charts = {
      ...state.charts,
      [chart.renderTo.id]: chart,
    }
  },
  setFullscreenChartId(state, chartId) {
    state.selectedFullscreenChartId = chartId
  },
}

const actions = {}

export default {
  getters,
  actions,
  mutations,
  state,
}
