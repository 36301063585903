import { privateApiConnectorService } from '@/services'

const state = {
  suppliersList: [],
  associatedPurchaser: null,
}

const getters = {
  getSuppliersList: (state) =>
    state.suppliersList.sort((a, b) => (a.supplier_name > b.supplier_name ? 1 : b.supplier_name > a.supplier_name ? -1 : 0)),
  getSelectedSupplier: (state, getters) => {
    const selectedSupplierName = getters.getSupplierName
    return state.suppliersList.find((supplier) => supplier.supplier_name === selectedSupplierName)
  },
  isAdmin: (state) => (state.associatedPurchaser ? state.associatedPurchaser === window.env.APP_PROVIDER : false),
}

const mutations = {
  setSuppliersList(state, suppliersList) {
    state.suppliersList = suppliersList
  },
  setAssociatedPurchaser(state, associatedPurchaser) {
    state.associatedPurchaser = associatedPurchaser
  },
}

const actions = {
  async initAdminData({ dispatch, getters }) {
    await dispatch('loadAssociatedPurchaser')
    const isAdmin = getters.isAdmin
    if (isAdmin) {
      await dispatch('loadSuppliersList')
    }
  },
  async loadAssociatedPurchaser({ commit }) {
    const associatedPurchaser = await privateApiConnectorService.getAssociatedPurchaser()
    if (associatedPurchaser) {
      commit('setAssociatedPurchaser', associatedPurchaser.purchaser)
    }
  },
  async updateSupplierId({ commit }, supplier) {
    await privateApiConnectorService.postSupplierId(supplier.id)
    commit('setSupplierName', supplier.supplier_name)
  },
  async loadSuppliersList({ commit }) {
    const suppliersList = await privateApiConnectorService.getSuppliersList()
    commit('setSuppliersList', suppliersList)
  },
}

export default {
  getters,
  actions,
  mutations,
  state,
}
