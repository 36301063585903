import TopToolbar from '@/components/shared/pageSections/headers/TopToolbar/TopToolbar.vue'
import CookiesBanner from '@/components/shared/pageSections/footers/CookiesBanner/CookiesBanner.vue'

export default {
  name: 'ConnectedLayout',
  data() {
    return {
      cookiesSettingsValidated: this.$store.getters.areUserCookiesPreferencesValidated,
    }
  },
  components: {
    TopToolbar,
    CookiesBanner,
  },
  async beforeMount() {
    await this.$store.dispatch('initStore')
  },
  computed: {
    isStoreInitiated() {
      return this.$store.state.isStoreInitiated
    },
  },
}
