import format from 'date-fns/format'
import parse from 'date-fns/parse'

const dateFormatterService = {
  formatDate(date) {
    return format(parse(date), 'DD/MM/YYYY')
  },
  isSameDate(date1, date2) {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
  },
  diffInDay(date1, date2) {
    const DAY_LENGTH = 24 * 60 * 60 * 1000
    return Math.round(Math.abs(date1.getTime() - date2.getTime()) / DAY_LENGTH)
  },
  isDayAnterior(date1, date2) {
    return (
      date1.getFullYear() < date2.getFullYear() ||
      (date1.getFullYear() === date2.getFullYear() && date1.getMonth() < date2.getMonth()) ||
      (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() < date2.getDate())
    )
  },
  getCorrespondingCampaign(date, campaignStartDate) {
    const d = new Date(date)
    const year = d.getFullYear()
    const campaignStartingMonth = Number(campaignStartDate.slice(2, 4)) - 1
    if (d.getMonth() < campaignStartingMonth) {
      return (year - 1).toString() + '-' + year.toString()
    } else {
      return year.toString() + '-' + (year + 1).toString()
    }
  },
  getPreviousCampaign(campaign) {
    return campaign
      .split('-')
      .map((y) => y - 1)
      .join('-')
  },
  formatCampaign(campaign) {
    return (
      '[' +
      campaign
        .split('-')
        .map((y) => y.slice(2))
        .join('/') +
      ']'
    )
  },
  formatCampaignPanelDate(panelDate) {
    return panelDate.slice(0, 2) + '/' + panelDate.slice(2, 4)
  },
  getMonthFromDate(date) {
    return date.getMonth() + 1
  },
  getPreviousCampaignLimitDate(limit) {
    const d = new Date(limit)
    let month = (d.getMonth() + 1).toString()
    let day = d.getDate().toString()
    const year = (d.getFullYear() - 1).toString()
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('-')
  },
  filterBeforeLimitDate(data, limitDate, key) {
    return data.filter((o) => {
      return o[key] <= limitDate
    })
  },
  getStringDateLastYear(dateStr) {
    const [day, month, year] = dateStr.split('/')
    return format(parse(new Date(year - 1, month - 1, day)), 'DD/MM/YYYY')
  },
  getYearForNewCampaign(startingMonthCampaign) {
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    if (startingMonthCampaign > currentMonth) return currentYear - 1
    return currentYear
  },

  calculateEndCampaignDate(startingYear, startingMonth, actualDate) {
    const endCampaignDate = new Date(Number(startingYear) + 1, startingMonth === 1 ? 12 : startingMonth, 0)
    endCampaignDate.setDate(endCampaignDate.getDate() - 1)
    return endCampaignDate < actualDate
      ? endCampaignDate.getFullYear() +
          '-' +
          ('0' + (endCampaignDate.getMonth() === 0 ? 12 : Number(endCampaignDate.getMonth()) + 1)).slice(-2) +
          '-' +
          endCampaignDate.getDate()
      : actualDate.getFullYear() +
          '-' +
          ('0' + (actualDate.getMonth() === 0 ? 12 : Number(actualDate.getMonth()) + 1)).slice(-2) +
          '-' +
          actualDate.getDate()
  },
  calculateEndPreviousCampaignDate(startYear, startMonth) {
    const endCampaign = new Date(startYear, Number(startMonth) - 1)
    endCampaign.setDate(endCampaign.getDate() - 1)
    return (
      (endCampaign.getMonth() === 0 ? endCampaign.getFullYear() - 1 : endCampaign.getFullYear()) +
      '-' +
      ('0' + (endCampaign.getMonth() === 0 ? 12 : Number(endCampaign.getMonth() + 1))).slice(-2) +
      '-' +
      endCampaign.getDate()
    )
  },
  getAllCampaignDates(startingMonth, startingCampaignYear, now) {
    const startingYear = new Date(startingCampaignYear, startingMonth - 1) > now ? startingCampaignYear - 1 : startingCampaignYear
    const currentCampaignStartingDate =
      (new Date(startingYear, startingMonth - 1) < now ? startingYear : startingYear - 1) + '-' + ('0' + startingMonth).slice(-2) + '-01'
    const currentCampaignEndDate = this.calculateEndCampaignDate(startingYear, startingMonth, now)
    const previousCampaignStartingDate =
      (new Date(startingYear, startingMonth - 1) < now ? startingYear - 1 : startingYear - 2) +
      '-' +
      ('0' + startingMonth).slice(-2) +
      '-01'
    const previousCampaignEndDate = this.calculateEndPreviousCampaignDate(startingYear, startingMonth)
    return [currentCampaignStartingDate, currentCampaignEndDate, previousCampaignStartingDate, previousCampaignEndDate]
  },
  getFormattedMonthFromDateTime(dateTime) {
    return format(new Date(dateTime), 'MM/YY')
  },
}
export default dateFormatterService
