import GenericCheckboxSelectors from '@/components/shared/checkboxSelectors/GenericCheckboxSelectors/GenericCheckboxSelectors.vue'
import TrackingService from '../../../../services/tracking.service'

export default {
  name: 'CampaignSelection',
  components: {
    GenericCheckboxSelectors,
  },
  computed: {
    states() {
      return this.campaignSelectionsGetter()
    },
  },
  methods: {
    campaignSelectionsGetter() {
      return this.$store.getters.getCampaignSelections
    },
    campaignSelectionSetter(newSelection) {
      this.$store.commit('setCampaignSelection', newSelection)
      TrackingService.trackEvent('click_checkbox_campaign', { additional_data: newSelection })
    },
  },
}
