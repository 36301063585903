import BigNumberIndicator from './BigNumberIndicator/BigNumberIndicator.vue'
import supplierSummaryDataService from '@/services/marketSegments/supplierSummaryData.service'

export default {
  name: 'MarketSegmentIndicators',
  components: {
    BigNumberIndicator,
  },
  computed: {
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    volumeUnit() {
      return this.$store.getters.getVolumeUnit
    },
    volumeUnitName() {
      return this.$store.getters.getVolumeUnitName
    },
    indicators() {
      const segmentationSource = this.$store.getters.getSelectedMarketSegmentSource
      const summary = this.$store.getters.getSelectedMarketSegmentIsSeed
        ? this.$store.getters.getSeedsIndividualMarketSupplierSummary(segmentationSource)
        : this.$store.getters.getPhytoIndividualMarketSupplierSummary(segmentationSource)
      const marketName = this.$store.getters.getSelectedMarketSegmentName
      const volumes = (summary[marketName] || {})[this.volumeUnit] || {}
      return supplierSummaryDataService.getSingleMarketIndicatorsFromVolumes(volumes, marketName)
    },
    marketVolume() {
      const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME
      return {
        text:
          '<div class="text-label--cap">' + this.volumeUnitName + `</div><div class="text-label--regular">Marché ${appProviderName}</div>`,
        value: this.indicators.marketCa,
        evolution: this.indicators.marketCaEvolution,
      }
    },
    supplierVolume() {
      return {
        text:
          '<div class="text-label--cap">' + this.volumeUnitName + '</div><div class="text-label--regular">' + this.supplierName + '</div>',
        value: this.indicators.supplierCa,
        evolution: this.indicators.supplierCaEvolution,
      }
    },
    supplierMarketShare() {
      return {
        text: '<div class="text-label--cap">Part de marché</div><div class="text-label--regular">' + this.supplierName + '</div>',
        value: this.indicators.marketShare,
        evolution: this.indicators.marketShareEvolution,
      }
    },
  },
}
