import MarketSegmentTitleAndControls from '@/components/MarketSegment/MarketSegmentTitleAndControls/MarketSegmentTitleAndControls.vue'
import MarketSegmentDataViz from '@/components/MarketSegment/MarketSegmentDataViz/MarketSegmentDataViz.vue'
import MarketSegmentIndicators from '@/components/MarketSegment/MarketSegmentIndicators/MarketSegmentIndicators.vue'

export default {
  name: 'MarketSegment',
  components: {
    MarketSegmentIndicators,
    MarketSegmentTitleAndControls,
    MarketSegmentDataViz,
  },
  data() {
    return {
      stickyOffsetControls: {
        top: -10,
        bottom: 0,
      },
      stickyOffsetIndicators: {
        top: 114,
        bottom: 0,
      },
    }
  },
  computed: {
    selectedMarketSegmentId() {
      return this.$store.getters.getSelectedMarketSegmentId
    },
    isDataloaded() {
      return this.$store.state.isDataloaded
    },
    currentCampaign() {
      return this.$store.getters.getSelectedStartingMonth
    },
  },
  watch: {
    async $route(to,) {
      if (to.name === 'market-segment') {
        const marketSegmentSlug = to.params.marketSegment
        const matchingMarketSegment = await this.$store.dispatch('setSelectedMarketSegmentFromSlugified', marketSegmentSlug,)
        await this.$store.dispatch('loadData', matchingMarketSegment.id,)
      }
    },
    async currentCampaign() {
      const segmentId = this.$store.getters.getSelectedMarketSegmentId
      await this.$store.dispatch('updateVolumeDataForNewCampaign', segmentId,)
    },
  },
  async beforeMount() {
    const matchingMarketSegment = await this.$store.dispatch('setSelectedMarketSegmentFromSlugified', this.$route.params.marketSegment,)
    await this.$store.dispatch('loadData', matchingMarketSegment.id,)
  },
}
