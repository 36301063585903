class TrackingService {
  static #instance
  #gtmPlugin
  // eslint-disable-next-line no-empty-function, no-useless-constructor
  constructor() {}

  static getInstance() {
    if (!this.#instance) {
      this.#instance = new TrackingService()
    }

    return this.#instance
  }

  setGtmPlugin(gtmPlugin) {
    this.#gtmPlugin = gtmPlugin
  }

  setPersistentDataLayer(data) {
    if (!this.#gtmPlugin) return

    const dataLayer = this.#gtmPlugin.dataLayer() ? this.#gtmPlugin.dataLayer() : []
    dataLayer.push({ ...data })
  }

  trackEvent(name, eventContext) {
    if (!this.#gtmPlugin) return

    const dataLayer = this.#gtmPlugin.dataLayer() ? this.#gtmPlugin.dataLayer() : []
    dataLayer.push({ ...eventContext })
    this.#gtmPlugin.trackEvent({ event: name })

    // Remove data from datalayer (if you want persitent data use fn setPersistentDataLayer)
    if (eventContext && Object.keys(eventContext).length > 0) {
      Object.keys(eventContext).forEach((k) => {
        dataLayer.push({ [k]: undefined })
      })
    }
  }

  removeFromDataLayer(name) {
    if (!this.#gtmPlugin) return

    const dataLayer = this.#gtmPlugin.dataLayer() ? this.#gtmPlugin.dataLayer() : []
    dataLayer.push({ [name]: undefined })
  }
}

export default TrackingService.getInstance()
