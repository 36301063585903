import cumulativeDataFormatter from '@/services/marketSegments/cumulativeDataFormatter.service'
import tooltipService from './tooltip.service'
import graphUtilityService from '../graphUtility.service'
import currencyFormatterService from '@/services/currencyFormatter.service'

const productsCumulativeCaService = {
  getLineChartData(productsApiData, campaigns, volumeUnit, visibleCampaigns) {
    const series = []

    const productsNameCurrent = Object.keys(productsApiData.current)

    for (let i = 0; i < productsNameCurrent.length; i++) {
      const currentProductName = productsNameCurrent[i]
      if (productsApiData.current.hasOwnProperty(currentProductName)) {
        series.push({
          name: currentProductName + ' current',
          color: graphUtilityService.getProductsFormattedColor(i).full,
          visible: visibleCampaigns.includes(campaigns.current),
          data: cumulativeDataFormatter.formatDataChart(productsApiData.current[currentProductName], volumeUnit, false),
          perimeter: currentProductName,
          campaign: 'current',
          ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
        })
      }
      if (productsApiData.previous.hasOwnProperty(currentProductName)) {
        series.push({
          name: currentProductName + ' previous',
          color: graphUtilityService.getProductsFormattedColor(i).full,
          visible: visibleCampaigns.includes(campaigns.previous),
          data: cumulativeDataFormatter.formatDataChart(productsApiData.previous[currentProductName], volumeUnit, true),
          perimeter: currentProductName,
          campaign: 'previous',
          ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
        })
      }
    }

    return {
      series,
    }
  },

  getLineChartOptions(volumeUnit) {
    const yAxisTitle = currencyFormatterService.getYAxisTitle(volumeUnit)

    return {
      chart: {
        type: 'spline',
        marginRight: 60,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        spline: {
          marker: {
            symbol: 'circle',
            enabled: false,
          },
        },
        series: {
          pointIntervalUnit: 'day',
        },
      },
      series: [],
      yAxis: {
        title: {
          text: '<b>' + yAxisTitle + '</b>',
        },
      },
      xAxis: {
        type: 'datetime',
        labels: {
          format: '{value:%d/%m}',
        },
        title: {
          text: '<b>Date</b>',
          align: 'high',
          reserveSpace: false,
          x: 40,
          y: 0,
        },
      },
      tooltip: {
        split: false,
        shared: true,
        useHTML: true,
        borderRadius: 4,
        borderWidth: 0,
        shadow: true,
        crosshairs: true,
        formatter: function () {
          return tooltipService.getTooltipHTML('spline', this.points, this.x, volumeUnit)
        },
      },
    }
  },
}

export default productsCumulativeCaService
