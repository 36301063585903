import axios from 'axios'
import format from 'date-fns/format'

const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token'

export default class PrivateApiConnectorService {
  constructor(apiHost) {
    this.apiHost = apiHost
  }

  get api() {
    return axios.create({
      baseURL: `${this.apiHost}/`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)}`,
      },
    })
  }

  async getMarketSegments() {
    return this.getFromApi('/segments-de-marche')
  }

  async getProductCountForSegment(marketSegmentId) {
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/nb-produits')
  }

  async getSupplierCountForSegment(marketSegmentId) {
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/nb-marques')
  }

  async getEvolutionCAForSegment(marketSegmentId, campaign) {
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/ca-evolution/' + campaign)
  }

  async getProductsListForSegment(marketSegmentId) {
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/liste-produits')
  }

  async getCumulativeCa(marketSegmentId, campaign) {
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/ca-cumule/' + campaign)
  }

  async getMonthlyCaForSegment(marketSegmentId, campaign) {
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/ca-mensuel/' + campaign)
  }

  async getProductsDailyCaForSegment(marketSegmentId, campaignStartDate, campaignEndDate) {
    const startDate = format(campaignStartDate, 'YYYY-MM')
    const endDate = format(campaignEndDate, 'YYYY-MM')
    return this.getFromApi(
      '/segments-de-marche/' + marketSegmentId + '/ca-quotidien-produits/?start_date=' + startDate + '&end_date=' + endDate
    )
  }

  async getDailyVolumesByMarket(marketSegmentId, campaignStartDate, campaignEndDate) {
    const startDate = format(campaignStartDate, 'YYYY-MM')
    const endDate = format(campaignEndDate, 'YYYY-MM')
    return this.getFromApi('/segments-de-marche/' + marketSegmentId + '/ca-quotidien/?start_date=' + startDate + '&end_date=' + endDate)
  }

  async getSupplierSummary(startingMonth) {
    return this.getFromApi('/synthese-fournisseur/' + startingMonth)
  }

  async getSupplierName() {
    return this.getFromApi('/users/me')
  }

  async getLastUpdateTime() {
    return this.getFromApi('/last-update')
  }

  enrichRouteWithAppProvider(route) {
    const delimiter = route.includes('?') ? '&' : '?'
    return route + delimiter + 'purchaser=' + window.env.APP_PROVIDER
  }

  async getFromApi(apiRoute) {
    return this.api
      .get(this.enrichRouteWithAppProvider(apiRoute))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw error
      })
  }

  putContactMessage(object, message) {
    return this.api
      .put('/contact', { object, message })
      .then((response) => {
        return 'Votre message a bien été envoyé, nous vous répondrons dès que possible.'
      })
      .catch((error) => {
        if (error.response.status === 401) {
          return 'You must be logged in to send a message'
        }
        throw error
      })
  }

  async postSupplierId(supplierId) {
    return this.api.post('/users/me', { supplierId }).then((response) => "User's supplier updated with supplier id : " + supplierId)
  }

  async getSuppliersList() {
    return this.getFromApi('/suppliers')
  }

  async getAssociatedPurchaser() {
    return this.getFromApi('/users/me/associated-purchaser')
  }
}
