const graphUtilityService = {
  THEME_PRIMARY_COLOR: '#304FFE',
  THEME_SECONDARY_COLOR: '#FF1744',
  THEME_PRODUCTS_COLORS: [
    '#64B5F6',
    '#64DD17',
    '#EEFF41',
    '#FFEA00',
    '#B71C1C',
    '#4A148C',
    '#0D47A1',
    '#1B5E20',
    '#827717',
    '#FFCC80',
    '#FF4081',
    '#EA80FC',
    '#18FFFF',
    '#1DE9B6',
    '#FF8F00',
    '#795548',
    '#880E4F',
    '#AA00FF',
    '#00ACC1',
    '#00695C',
    '#000000',
    '#607D8B',
  ],
  CURRENT_CAMPAIGN_SERIE_LINE_STYLE: {
    dashStyle: 'solid',
    borderWidth: 1,
    lineWidth: 1,
  },
  PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE: {
    dashStyle: 'dash',
    borderWidth: 1,
    lineWidth: 1,
  },
  getProductsFormattedColor(index) {
    const COLORS_LIST_SIZE = this.THEME_PRODUCTS_COLORS.length
    let position = index
    while (position >= COLORS_LIST_SIZE) {
      position = position - COLORS_LIST_SIZE
    }
    return this.formatColor(this.THEME_PRODUCTS_COLORS[position])
  },
  formatColor(color) {
    const rgbColor = this.hexToRGB(color)
    return {
      full: 'rgba(' + Object.values(rgbColor).join(', ') + ', 1)',
      opaque: 'rgba(' + Object.values(rgbColor).join(', ') + ', .2)',
    }
  },
  hexToRGB(hex) {
    const regexGroups = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return regexGroups
      ? { r: parseInt(regexGroups[1], 16), g: parseInt(regexGroups[2], 16), b: parseInt(regexGroups[3], 16) }
      : { r: 0, g: 0, b: 0 }
  },
  computeItemId(item) {
    return item.replace(/ /g, '_').replace("'", '').toLowerCase()
  },
  formatLegendItem(name, color, display, existsInCurrentCampaign = true) {
    return {
      id: this.computeItemId(name),
      name,
      color,
      display,
      existsInCurrentCampaign,
    }
  },
  formatRGBColorWithoutOpacity(rgb) {
    const regexGroups = /^rgba\((\d{1,3}%?,\s?\d{1,3}%?,\s?\d{1,3}%?),\s?(1|0?\.\d+)\)$/i.exec(rgb)
    return 'rgba(' + regexGroups[1] + ', 1)'
  },
  formatRGBColorWithOpacity(rgb) {
    const regexGroups = /^rgba\((\d{1,3}%?,\s?\d{1,3}%?,\s?\d{1,3}%?),\s?(1|0?\.\d+)\)$/i.exec(rgb)
    return 'rgba(' + regexGroups[1] + ', .2)'
  },
  getDataSeriesMaxValue(array) {
    return array.map((data) => data.y).reduce((accumulator, currentValue) => Math.max(accumulator, currentValue), Number.NEGATIVE_INFINITY)
  },
  getDataSeriesMinValue(array) {
    return array.map((data) => data.y).reduce((accumulator, currentValue) => Math.min(accumulator, currentValue), Number.POSITIVE_INFINITY)
  },
  computeDoubleAxisBounds(firstAxisMax, firstAxisMin, secondAxisMax, secondAxisMin) {
    let computedYAxis = [
      {
        max: firstAxisMax,
        min: 0,
      },
      {
        max: secondAxisMax,
        min: 0,
      },
    ]
    if (firstAxisMax <= 0 && firstAxisMin <= 0 && secondAxisMax <= 0 && secondAxisMin <= 0) {
      computedYAxis = [
        {
          max: 0,
          min: firstAxisMin,
        },
        {
          max: 0,
          min: secondAxisMin,
        },
      ]
    } else {
      if (firstAxisMin < 0 || secondAxisMin < 0) {
        if ((secondAxisMax <= 0 && firstAxisMin >= 0) || (firstAxisMax <= 0 && secondAxisMin >= 0)) {
          computedYAxis = [
            {
              max: Math.max(firstAxisMax, Math.abs(firstAxisMin)),
              min: Math.min(firstAxisMin, -Math.abs(firstAxisMax)),
            },
            {
              max: Math.max(secondAxisMax, Math.abs(secondAxisMin)),
              min: Math.min(secondAxisMin, -Math.abs(secondAxisMax)),
            },
          ]
        } else if (firstAxisMin >= 0 || secondAxisMin >= 0) {
          computedYAxis = [
            {
              max: firstAxisMax,
              min: secondAxisMin >= 0 ? firstAxisMin : firstAxisMax * (secondAxisMin / secondAxisMax),
            },
            {
              max: secondAxisMax,
              min: secondAxisMin >= 0 ? secondAxisMax * (firstAxisMin / firstAxisMax) : secondAxisMin,
            },
          ]
        } else if (firstAxisMax <= 0 || secondAxisMax <= 0) {
          computedYAxis = [
            {
              max: secondAxisMax <= 0 ? firstAxisMax : firstAxisMin * (secondAxisMax / secondAxisMin),
              min: firstAxisMin,
            },
            {
              max: secondAxisMax <= 0 ? secondAxisMin * (firstAxisMax / firstAxisMin) : secondAxisMax,
              min: secondAxisMin,
            },
          ]
        } else {
          const marketDelta = firstAxisMax - firstAxisMin
          const supplierDelta = secondAxisMax - secondAxisMin

          const marketRatioMax = Math.abs(firstAxisMax / marketDelta)
          const marketRatioMin = Math.abs(firstAxisMin / marketDelta)
          const supplierRatioMax = Math.abs(secondAxisMax / supplierDelta)
          const supplierRatioMin = Math.abs(secondAxisMin / supplierDelta)

          computedYAxis = [
            {
              max: marketRatioMax <= supplierRatioMax ? firstAxisMax * (supplierRatioMax / marketRatioMax) : firstAxisMax,
              min: marketRatioMin <= supplierRatioMin ? firstAxisMin * (supplierRatioMin / marketRatioMin) : firstAxisMin,
            },
            {
              max: marketRatioMax > supplierRatioMax ? secondAxisMax * (marketRatioMax / supplierRatioMax) : secondAxisMax,
              min: marketRatioMin > supplierRatioMin ? secondAxisMin * (marketRatioMin / supplierRatioMin) : secondAxisMin,
            },
          ]
        }
      }
    }
    return computedYAxis
  },
  computeDoubleAxisBoundsFromSeries(firstAxisSeries, secondAxisSeries) {
    const firstAxisMax = Math.max(...firstAxisSeries.map(this.getDataSeriesMaxValue))
    const firstAxisMin = Math.min(...firstAxisSeries.map(this.getDataSeriesMinValue))
    const secondAxisMax = Math.max(...secondAxisSeries.map(this.getDataSeriesMaxValue))
    const secondAxisMin = Math.min(...secondAxisSeries.map(this.getDataSeriesMinValue))
    return this.computeDoubleAxisBounds(firstAxisMax, firstAxisMin, secondAxisMax, secondAxisMin)
  },
}

export default graphUtilityService
