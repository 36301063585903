import CumulativeVolumeProduct from './CumulativeVolumeProduct/CumulativeVolumeProduct.vue'
import CumulativeVolumeSupplier from './CumulativeVolumeSupplier/CumulativeVolumeSupplier.vue'
import MonthlyVolume from './MonthlyVolume/MonthlyVolume.vue'
import ProductsDistribution from './ProductsDistribution/ProductsDistribution.vue'
import TwoChartsContainer from './TwoChartsContainer/TwoChartsContainer.vue'
import EvolutionMarketShareSupplier from './EvolutionMarketShareSupplier/EvolutionMarketShareSupplier.vue'

export default {
  name: 'MarketSegmentDataViz',
  components: {
    TwoChartsContainer,
    CumulativeVolumeProduct,
    CumulativeVolumeSupplier,
    MonthlyVolume,
    ProductsDistribution,
    EvolutionMarketShareSupplier,
  },
  data() {
    return {
      chartsContainers: {
        marketEvolution: {
          title: 'Évolution du marché',
          showChartsLegend: true,
          showChartsLegendButtonsArea: false,
          charts: {
            cumulativeVolumeSupplier: {
              id: 'js-line-chart-cumulative-ca-container',
            },
            monthlyVolume: {
              id: 'js-column-chart-monthly-ca-container',
            },
          },
        },
        productsBreakdown: {
          title: 'Répartition par produits',
          showChartsLegend: true,
          showChartsLegendButtonsArea: true,
          charts: {
            productsDistribution: {
              id: 'js-bar-chart-products-rate-container',
            },
            cumulativeVolumeProduct: {
              id: 'js-line-chart-products-cumulative-ca-container',
            },
          },
        },
        marketShareEvolution: {
          title: 'Évolution de la part de marché',
          showChartsLegend: false,
          showChartsLegendButtonsArea: false,
          charts: {
            evolutionMarketShareSupplier: {
              id: 'js-line-chart-evolution-market-share-supplier-container',
            },
          },
        },
      },
    }
  },
  computed: {
    lastModified() {
      return this.$store.getters.getLastUpdateTime
    },
  },
  methods: {
    chartIds(chartsContainer) {
      return Object.keys(chartsContainer.charts).map((chartKey) => chartsContainer.charts[chartKey].id)
    },
    slotName(index) {
      return 'chart' + (index + 1)
    },
  },
}
