import CurrencyAreaSwitch from '@/components/shared/switchButtons/CurrencyAreaSwitch/CurrencyAreaSwitch.vue'
import DataBillingStatusSwitch from '@/components/shared/switchButtons/DataBillingStatusSwitch/DataBillingStatusSwitch.vue'
import PeriodSelector from '@/components/shared/PeriodSelector/PeriodSelector.vue'
import TrackingService from '../../../services/tracking.service'

export default {
  name: 'SummaryTitleAndControls',
  components: {
    CurrencyAreaSwitch,
    DataBillingStatusSwitch,
    PeriodSelector,
  },
  props: ['title', 'marketType', 'isSummaryOnGroupView', 'summaryHasGroupView', 'marketSegmentationSource'],
  computed: {
    disableNonCurrencyUnit() {
      return this.marketType === 'seeds' && this.marketSegmentationSource === 'CHALLENGE'
    },
  },
  methods: {
    handleSummarySwitch(value) {
      // Manually triggering scroll event to reset the sticky feature when swicthing summary tab
      window.dispatchEvent(new Event('scroll'))
      TrackingService.trackEvent('page_summary_click_tab', { additional_data: value ? 'group' : 'segment' })
      this.$emit('click-summary-switch', value)
    },
  },
}
