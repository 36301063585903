import stringFormatterService from '@/services/stringFormatter.service'
import GenericCloseButton from '@/components/shared/popins/GenericCloseButton/GenericCloseButton.vue'
import ColumnHeader from '@/components/shared/dataTables/ColumnHeader/ColumnHeader.vue'
import TrackingService from '../../../../services/tracking.service'

export default {
  name: 'ProductList',
  components: {
    GenericCloseButton,
    ColumnHeader,
  },
  props: {
    title: {
      type: String,
    },
    segmentId: String,
    disableSorting: Boolean,
  },
  data() {
    return {
      headers: [
        {
          text: 'Nom',
          value: 'product',
          sortable: true,
          align: 'left',
        },
        {
          text: 'Dose/Ha',
          value: 'doseHa',
          sortable: true,
          align: 'center',
        },
        {
          text: 'Unité',
          value: 'unitDose',
          sortable: true,
          align: 'center',
        },
      ],
      pagination: {
        descending: this.$store.getters.getLastConfigProductList.paginationDesc,
        rowsPerPage: -1,
        sortBy: this.$store.getters.getLastConfigProductList.column,
      },
      sortClickOnSameColumn: 0,
    }
  },
  computed: {
    productsList() {
      return this.$store.getters.getSortedProductsList
    },
    nbProduct() {
      return this.$store.getters.getProductsCount
    },
    selectedMarketSegmentId() {
      return this.segmentId
    },
    isDataloaded() {
      return this.$store.state.isDataloaded
    },
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.sortClickOnSameColumn++
        if (this.sortClickOnSameColumn === 2) {
          this.pagination.sortBy = 'isOwnProduct'
          this.sortClickOnSameColumn = 0
        } else {
          this.pagination.descending = !this.pagination.descending
        }
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
        this.sortClickOnSameColumn = 0
      }
      const lastConfig = {
        column: this.pagination.sortBy,
        paginationDesc: this.pagination.descending,
        nbClickSameColumn: this.sortClickOnSameColumn,
      }
      this.$store.commit('setlastConfigProductList', lastConfig)
      TrackingService.trackEvent('modal_product_list_click_header_order_by', { additional_data: lastConfig.column })
    },
    closePopin() {
      this.$parent.$parent.$emit('close-popin')
    },
    isDropArrow(columnSelected, direction) {
      if (this.pagination.sortBy && this.pagination.sortBy === columnSelected) {
        const pagination = direction === 'down' ? this.pagination.descending : !this.pagination.descending
        if (!pagination) {
          return true
        }
      }
      return false
    },
  },
  async beforeMount() {
    const slugifiedMarketSegment = stringFormatterService.slugify(this.segmentId)
    const matchingMarketSegment = await this.$store.dispatch('setSelectedMarketSegmentFromSlugified', slugifiedMarketSegment)
    await this.$store.dispatch('updateReferentialData', matchingMarketSegment.id)
    this.sortClickOnSameColumn = this.$store.getters.getLastConfigProductList.nbClickSameColumn
    this.$store.commit('setIsDataLoaded', true)
    TrackingService.trackEvent('page_summary_table_click_btn_product_list', { additional_data: slugifiedMarketSegment })
  },
}
