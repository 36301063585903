import dateFormatterService from './../dateFormatter.service'
import graphUtilityService from './../graphUtility.service'
import tooltipService from '@/services/marketSegments/tooltip.service'

// This service provides functions to process API data for cumulative volume HighChart chart
const evolutionMarketShare = {
  formatData(marketCumulativeVolume, supplierCumulativeVolume, volumeUnit, isPrevious) {
    return Array.from(Array(marketCumulativeVolume.length).keys()).map((index) => {
      const supplierValue = supplierCumulativeVolume[index]
      const marketValue = marketCumulativeVolume[index]
      const day = marketValue.day
      if (supplierValue) {
        const date = new Date(marketValue.day)
        return {
          x: isPrevious ? new Date(Date.UTC(date.getFullYear() + 1, date.getMonth(), date.getDate())).getTime() : day,
          y: this.computeMarketShare(supplierValue[volumeUnit], marketValue[volumeUnit]),
        }
      }
    })
  },

  computeMarketShare(supplierValue, marketValue) {
    let marketShare
    if (supplierValue === 0 || marketValue === 0) marketShare = 0
    else if (supplierValue > marketValue) marketShare = 1
    else if (supplierValue < 0) marketShare = 0
    else marketShare = supplierValue / marketValue
    return marketShare * 100
  },

  getLineChartData(supplierName, marketSegmentApiData, supplierApiData, campaigns, volumeUnit, visibleCampaigns, startDate, endDate) {
    const currentCampaign = campaigns.current
    const previousCampaign = campaigns.previous

    const marketShareCurrent = {
      name: 'Part de marché ' + supplierName + ' ' + dateFormatterService.formatCampaign(currentCampaign),
      visible: visibleCampaigns.includes(campaigns.current),
      data: this.formatData(marketSegmentApiData.current, supplierApiData.current, volumeUnit, false),
      campaign: 'current',
      perimeter: 'PDM ' + supplierName,
      ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
    }
    const marketSharePrevious = {
      name: 'Part de marché ' + supplierName + ' ' + dateFormatterService.formatCampaign(previousCampaign),
      visible: visibleCampaigns.includes(campaigns.previous),
      data: this.formatData(marketSegmentApiData.previous, supplierApiData.previous, volumeUnit, true),
      campaign: 'previous',
      perimeter: 'PDM ' + supplierName,
      ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
    }
    return {
      series: [marketShareCurrent, marketSharePrevious],
    }
  },

  getLineChartOptions() {
    return {
      chart: {
        type: 'spline',
        marginRight: 60,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        enabled: false,
      },
      series: [],
      yAxis: {
        title: {
          text: '<b>Part de marché (%)</b>',
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%d/%m',
        },
        title: {
          text: '<b>Date</b>',
          align: 'high',
          reserveSpace: false,
          x: 40,
          y: -15,
        },
      },
      plotOptions: {
        spline: {
          marker: {
            symbol: 'circle',
            enabled: false,
          },
        },
      },
      tooltip: {
        split: false,
        shared: true,
        useHTML: true,
        borderRadius: 4,
        borderWidth: 0,
        shadow: true,
        crosshairs: true,
        formatter: function () {
          return tooltipService.getTooltipHTML('spline', this.points, this.x, 'marketShare')
        },
      },
      colors: [graphUtilityService.formatColor(graphUtilityService.THEME_SECONDARY_COLOR).full],
    }
  },
}

export default evolutionMarketShare
