import GenericCloseButton from '@/components/shared/popins/GenericCloseButton/GenericCloseButton.vue'

export default {
  name: 'Cookies',
  components: {
    GenericCloseButton,
  },
  computed: {
    isUserAnalyticsCookiesApproved: {
      get() {
        return this.$store.getters.getUserAnalyticsCookiesPreferences
      },
      set(newUserApproval) {
        this.$store.dispatch('saveUserAnalyticsCookiesPreferences', newUserApproval)
      },
    },
  },
  methods: {
    submitForm() {
      this.closePopin()
    },
    closePopin() {
      this.$parent.$parent.$emit('close-popin')
    },
  },
}
