import BigNumberIndicator from '@/components/MarketSegment/MarketSegmentIndicators/BigNumberIndicator/BigNumberIndicator.vue'
import ColumnHeader from '@/components/shared/dataTables/ColumnHeader/ColumnHeader.vue'
import MarketTableCell from '@/components/shared/dataTables/MarketTableCell/MarketTableCell.vue'
import stringFormatterService from '@/services/stringFormatter.service'
import supplierSummaryDataService from '@/services/marketSegments/supplierSummaryData.service'
import GenericPopin from '@/components/shared/popins/GenericPopin/GenericPopin.vue'
import ProductList from '@/components/shared/popins/ProductList/ProductList.vue'
import TrackingService from '../../../services/tracking.service'

export default {
  name: 'IndividualMarketTable',
  props: {
    isSeedsSummary: Boolean,
    isStickyHeader: Boolean,
    isStickyEnabled: Boolean,
    targetMarketGroup: {
      default: null,
      type: String,
    },
    marketSegmentationSource: {
      default: 'SCOR',
      type: String,
    },
    title: String,
    segmentId: String,
    disableSorting: Boolean,
  },
  components: {
    BigNumberIndicator,
    ColumnHeader,
    MarketTableCell,
    ProductList,
    GenericPopin,
  },
  data() {
    return {
      pagination: {
        descending: this.isSeedsSummary
          ? this.$store.getters.getLastConfigSupplierSummary.seeds.paginationDesc
          : this.$store.getters.getLastConfigSupplierSummary.phyto.paginationDesc,
        rowsPerPage: -1,
        sortBy: this.isSeedsSummary
          ? this.$store.getters.getLastConfigSupplierSummary.seeds.column
          : this.$store.getters.getLastConfigSupplierSummary.phyto.column,
      },
    }
  },
  computed: {
    campaignStartingMonth() {
      return this.$store.getters.getSelectedStartingMonth
    },
    marketSegmentsList() {
      let summary
      if (this.targetMarketGroup) {
        summary = this.isSeedsSummary
          ? this.$store.getters.getSeedsIndividualMarketSupplierSummaryFromGroup(this.targetMarketGroup)
          : this.$store.getters.getPhytoIndividualMarketSupplierSummaryFromGroup(this.targetMarketGroup)
      } else {
        summary = this.isSeedsSummary
          ? this.$store.getters.getSeedsIndividualMarketSupplierSummary(this.marketSegmentationSource)
          : this.$store.getters.getPhytoIndividualMarketSupplierSummary(this.marketSegmentationSource)
      }
      return summary ? supplierSummaryDataService.getIndividualMarketSupplierSummaryByVolumeType(summary, this.volumeUnit) : []
    },
    volumeUnit() {
      return this.isCurrency ? 'ca' : this.isSeedsSummary ? 'dose' : 'ha'
    },
    isCurrency() {
      return this.isSeedsSummary
        ? this.$store.getters.getVolumeUnitSwitchIsCurrency(this.marketSegmentationSource, 'seeds')
        : this.$store.getters.getVolumeUnitSwitchIsCurrency(this.marketSegmentationSource, 'phyto')
    },
    headerTotalMarket() {
      return this.isCurrency ? "Chiffre d'affaires " : this.isSeedsSummary ? 'Doses ' : 'Hectare '
    },
    columnHeaders() {
      const appProviderName = window.env.APP_PROVIDER_DISPLAYED_NAME
      return [
        {
          text: '<div class="text-label--cap">Segment</div><div class="text-label--regular">de marché</div>',
          value: 'market',
          sortable: true,
        },
        {
          text:
            '<div class="text-label--cap">' + this.headerTotalMarket + `</div><div class="text-label--regular">  ${appProviderName}</div>`,
          value: 'marketCa',
          sortable: true,
        },
        {
          text:
            '<div class="text-label--cap">' +
            this.headerTotalMarket +
            '</div><div class="text-label--regular">' +
            this.$store.state.connectedSupplierName +
            '</div>',
          value: 'supplierCa',
          sortable: true,
        },
        {
          text:
            '<div class="text-label--cap">Part de marché</div><div class="text-label--regular">' +
            this.$store.state.connectedSupplierName +
            '</div>',
          value: 'marketShare',
          sortable: true,
        },
        {
          text: '<div class="text-label--regular"></div>',
          value: 'product',
          sortable: false,
        },
      ]
    },
    getLastConfigSupplierSummary() {
      return this.$store.getters.getLastConfigSupplierSummary
    },
  },
  watch: {
    getLastConfigSupplierSummary: {
      handler: function (lastConfigSupplierSummary) {
        if (lastConfigSupplierSummary) {
          this.pagination = {
            descending: this.isSeedsSummary
              ? this.$store.getters.getLastConfigSupplierSummary.seeds.paginationDesc
              : this.$store.getters.getLastConfigSupplierSummary.phyto.paginationDesc,
            rowsPerPage: -1,
            sortBy: this.isSeedsSummary
              ? this.$store.getters.getLastConfigSupplierSummary.seeds.column
              : this.$store.getters.getLastConfigSupplierSummary.phyto.column,
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    marketSegmentRoute(marketSegmentName) {
      const slugifiedMarket = stringFormatterService.slugify(marketSegmentName)
      return 'segment-de-marche/' + slugifiedMarket
    },
    productListRoute(marketSegmentName) {
      const slugifiedMarket = stringFormatterService.slugify(marketSegmentName)
      return '/segment-de-marche/' + slugifiedMarket + '/liste-des-produits'
    },
    changeSort(columnSelected) {
      const lastConfig = this.isSeedsSummary
        ? Object.assign({}, this.$store.getters.getLastConfigSupplierSummary.seeds)
        : Object.assign({}, this.$store.getters.getLastConfigSupplierSummary.phyto)

      if (lastConfig.column === columnSelected) {
        this.pagination.descending = !this.pagination.descending
        lastConfig.paginationDesc = this.pagination.descending
      } else {
        this.pagination.sortBy = columnSelected
        this.pagination.descending = false
        lastConfig.column = columnSelected
        lastConfig.paginationDesc = this.pagination.descending
      }

      if (this.isSeedsSummary) {
        this.$store.commit('setSeedsLastConfigSupplierSummary', lastConfig)
      } else {
        this.$store.commit('setPhytoLastConfigSupplierSummary', lastConfig)
      }

      TrackingService.trackEvent('page_summary_click_table_header_order_by', { additional_data: lastConfig.column })
    },
    isDropArrow(columnSelected, direction) {
      if (this.pagination.sortBy && this.pagination.sortBy === columnSelected) {
        const pagination = direction === 'up' ? this.pagination.descending : !this.pagination.descending
        if (!pagination) {
          return true
        }
      }
      return false
    },
  },
}
