const SUFFIX = { ca: '€', ha: 'ha', dose: 'Do' }
const RANGES_SCALE = [
  { divider: 1e5, divisor: 1e3, precision: 0, suffix: 'k' },
  { divider: 1e4, divisor: 1e3, precision: 1, suffix: 'k' },
  { divider: 1e3, divisor: 1e3, precision: 2, suffix: 'k' },
  { divider: 100, divisor: 1e3, precision: 3, suffix: 'k' },
  { divider: 10, divisor: 1e3, precision: 3, suffix: 'k' },
  { divider: 1, divisor: 1e3, precision: 3, suffix: 'k' },
]

/* eslint-disable indent */
const currencyFormatterService = {
  availableUnits: ['ca', 'ha', 'dose'],
  getYAxisTitle(volumeUnit) {
    switch (volumeUnit) {
      case 'ca':
        return 'CA (en €)'
      case 'ha':
        return 'CA (en ha)'
      case 'dose':
        return 'CA (en doses)'
      default:
        throw new Error('Unknown volume unit : ' + volumeUnit)
    }
  },
  getMonthlyVolumeColumnName(volumeUnit) {
    switch (volumeUnit) {
      case 'ca':
        return 'totalCA'
      case 'ha':
        return 'totalHA'
      case 'dose':
        return 'totalDose'
      default:
        throw new Error('Unknown volume unit : ' + volumeUnit)
    }
  },
  getFullUnitName(volumeUnit) {
    switch (volumeUnit) {
      case 'ca':
        return "Chiffre d'affaires"
      case 'ha':
        return 'Hectare'
      case 'dose':
        return 'Dose'
      default:
        throw new Error('Unknown volume unit : ' + volumeUnit)
    }
  },

  /* eslint-enable indent */
  formatThousands(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  },
  formatValue(value, volumeUnit) {
    if (volumeUnit === 'ca') return this.formatCA(value)
    else if (volumeUnit === 'ha') return this.formatHa(value)
    else if (volumeUnit === 'dose') return this.formatDose(value)
    else throw new Error('Unknown volume type : ' + volumeUnit)
  },
  formatCA(ca) {
    if (ca) {
      let caAsNumber = ca
      if (typeof ca === 'string') caAsNumber = Number(ca)
      for (let rangeScaleNum = 0; rangeScaleNum < RANGES_SCALE.length; rangeScaleNum++) {
        const range = RANGES_SCALE[rangeScaleNum]
        if (Math.abs(caAsNumber) >= range.divider) {
          const convertedValue = caAsNumber / range.divisor
          const roundedValue =
            convertedValue % 1 <= 5 && range.precision === 0 ? Math.floor(convertedValue) : convertedValue.toFixed(range.precision)
          return (this.formatThousands(roundedValue) + ' ' + range.suffix + SUFFIX.ca).replace('.', ',')
        }
      }
      return (this.formatThousands(caAsNumber.toFixed(1)) + ' ' + SUFFIX.ca).replace('.', ',')
    }
    return '0,0 ' + SUFFIX.ca
  },
  formatHa(ha) {
    if (ha) {
      let haAsNumber = ha
      if (typeof ha === 'string') haAsNumber = Number(ha)
      for (let rangeScaleNum = 0; rangeScaleNum < RANGES_SCALE.length; rangeScaleNum++) {
        const range = RANGES_SCALE[rangeScaleNum]
        if (Math.abs(haAsNumber) >= range.divider) {
          const convertedValue = haAsNumber / range.divisor
          const roundedValue =
            convertedValue % 1 <= 5 && range.precision === 0 ? Math.floor(convertedValue) : convertedValue.toFixed(range.precision)
          return (this.formatThousands(roundedValue) + ' ' + range.suffix + SUFFIX.ha).replace('.', ',')
        }
      }
      return (this.formatThousands(haAsNumber.toFixed(1)) + ' ' + SUFFIX.ha).replace('.', ',')
    }
    return '0,0 ' + SUFFIX.ha
  },
  formatDose(dose) {
    if (dose) {
      let doseAsNumber = dose
      if (typeof dose === 'string') doseAsNumber = Number(dose)
      for (let rangeScaleNum = 0; rangeScaleNum < RANGES_SCALE.length; rangeScaleNum++) {
        const range = RANGES_SCALE[rangeScaleNum]
        if (Math.abs(doseAsNumber) >= range.divider) {
          const convertedValue = doseAsNumber / range.divisor
          const roundedValue =
            convertedValue % 1 <= 5 && range.precision === 0 ? Math.floor(convertedValue) : convertedValue.toFixed(range.precision)
          return (this.formatThousands(roundedValue) + ' ' + range.suffix + SUFFIX.dose).replace('.', ',').trim()
        }
      }
      return (this.formatThousands(doseAsNumber.toFixed(1)) + ' ' + SUFFIX.dose).replace('.', ',').trim()
    }
    return ('0,0 ' + SUFFIX.dose).trim()
  },

  formatEvolution(caEvolution) {
    if (!caEvolution) return '--'
    else {
      const formattedEvolution =
        Math.abs(caEvolution) < 9.95 ? Number(caEvolution).toFixed(1).toString() : Math.round(caEvolution).toString()
      if (!isFinite(formattedEvolution)) return '--'
      if (caEvolution > 0) return '+' + formattedEvolution.replace('.', ',') + ' %'
      return formattedEvolution.replace('.', ',') + ' %'
    }
  },

  isEvolutionGrowing(caEvolution) {
    if (!caEvolution) return undefined
    if (caEvolution > 0) return true
    else return false
  },

  isEvolutionDecreasing(caEvolution) {
    if (!caEvolution) return undefined
    if (caEvolution < 0) return true
    else return false
  },

  formatMarketShare(marketShare) {
    if (!marketShare) return '--'
    const formattedShare = (Number(marketShare).toFixed(1) + ' %').replace('.', ',')
    return formattedShare
  },
  formatMarketShareEvolution(marketShareEvolution) {
    if (!marketShareEvolution) return '--'
    const truncatedEvolution = Number(marketShareEvolution).toFixed(1)
    if (truncatedEvolution > 0) return '+' + truncatedEvolution.replace('.', ',') + '<span> pts</span>'
    else return truncatedEvolution.replace('.', ',') + '<span> pts</span>'
  },
}

export default currencyFormatterService
