import { productsCumulativeCaService } from '@/services/index'
import ChartContainer from '@/components/shared/charts/ChartContainer/ChartContainer.vue'

export default {
  name: 'CumulativeVolumeProduct',
  components: {
    ChartContainer,
  },
  props: ['chartId', 'legendChartIds', 'isFullscreenLegendEnabled', 'isFullscreenLegendButtonsAreaEnabled'],
  computed: {
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns
    },
    volumeUnit() {
      return this.$store.getters.getVolumeUnit
    },
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    chartTitle() {
      return this.$store.getters.getVolumeUnitName
    },
    chartOptions() {
      return productsCumulativeCaService.getLineChartOptions(this.volumeUnit)
    },
    chartData() {
      const productsCumulativeVolumes = this.$store.getters.getProductsCumulativeVolumes
      const campaigns = {
        current: this.$store.getters.getCurrentCampaignObject.toString(),
        previous: this.$store.getters.getCurrentCampaignObject.getPrevious().toString(),
      }

      return productsCumulativeCaService.getLineChartData(productsCumulativeVolumes, campaigns, this.volumeUnit, this.enabledCampaigns)
    },
  },
}
