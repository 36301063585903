import GenericSwitch from '@/components/shared/switchButtons/GenericSwitch/GenericSwitch.vue'
import TrackingService from '../../../../services/tracking.service'

export default {
  name: 'CurrencyAreaSwitch',
  props: ['marketType', 'marketSegmentationSource', 'disableNonCurrencyUnit'],
  components: {
    GenericSwitch,
  },
  computed: {
    isCurrencyDisabled() {
      return this.$store.getters.isCurrencyDisabled
    },
    states() {
      return [
        {
          storeValue: true,
          label: '€',
          disabled: this.isCurrencyDisabled,
        },
        {
          storeValue: false,
          label: this.nonCurrencyUnit(),
          disabled: this.disableNonCurrencyUnit,
        },
      ]
    },
  },
  methods: {
    nonCurrencyUnit() {
      if (this.marketType === 'phyto') return 'ha'
      else if (this.marketType === 'seeds') return 'Do'
      else throw new Error('Unknown market type : ' + this.marketType)
    },
    unitGetter() {
      if (this.marketType === 'phyto') {
        return this.$store.getters.getVolumeUnitSwitchIsCurrency(this.marketSegmentationSource, 'phyto')
      } else if (this.marketType === 'seeds') {
        return this.$store.getters.getVolumeUnitSwitchIsCurrency(this.marketSegmentationSource, 'seeds')
      } else throw new Error('Unknown market type : ' + this.marketType)
    },
    unitSetter(newState) {
      const mutationPayload = {
        marketSegmentation: this.marketSegmentationSource,
        phytoOrSeeds: this.marketType,
        newSwitchState: newState,
      }
      this.$store.commit('setVolumeUnitSwitchIsCurrency', mutationPayload)
      TrackingService.trackEvent('click_toggle_units', { additional_data: newState ? 'euros' : 'ha' })
    },
  },
}
