import { privateApiConnectorService } from '../../services/index'
import GenericCloseButton from '@/components/shared/popins/GenericCloseButton/GenericCloseButton.vue'
import TrackingService from '../../services/tracking.service'

export default {
  name: 'Contact',
  components: {
    GenericCloseButton,
  },
  data() {
    return {
      formSubmitted: false,
      success: null,
      object: '[Invivo - Portail fournisseur] Prise de Contact',
      message: '',
      sendStatus: '',
    }
  },
  methods: {
    async send() {
      try {
        const response = await privateApiConnectorService.putContactMessage(this.object, this.message)
        this.success = true
        this.sendStatus = response
        TrackingService.trackEvent('modal_contact_click_send')
      } catch (e) {
        this.success = false
        this.sendStatus = "Erreur: votre message n'a pas pu être envoyé"
      } finally {
        this.formSubmitted = true
      }
    },
    closePopin() {
      this.$parent.$parent.$emit('close-popin')
    },
  },
}
