import TopBasicbar from '@/components/shared/pageSections/headers/TopBasicbar/TopBasicbar.vue'
import { authService } from '../../services/index'

export default {
  name: 'Login',
  components: {
    TopBasicbar,
  },
  data() {
    return {
      email: '',
      password: '',
      passwordVisibility: false,
      error: null,
    }
  },
  computed: {
    isEmailNotEmpty() {
      this.error = null
      return this.isNotEmpty(this.email) || "L'email est obligatoire"
    },
    isEmailWellFormatted() {
      this.error = null
      return this.isEmailValid(this.email) || "Le format de l'email n'est pas valide"
    },
    isPasswordNotEmpty() {
      this.error = null
      return this.isNotEmpty(this.password) || 'Le mot de passe est obligatoire'
    },
    submitDisabled() {
      return !this.isEmailValid(this.email) || !this.isNotEmpty(this.email) || !this.isNotEmpty(this.password)
    },
  },
  methods: {
    isNotEmpty(value) {
      return !(!value || value.length === 0)
    },
    isEmailValid(value) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value)
    },
    async authenticate() {
      const { email, password } = this
      try {
        await authService.authenticate({ email, password })
        this.error = null
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.error = 'Email et/ou mot de passe invalide'
        } else if (error.response && error.response.status === 500) {
          this.error = 'Une erreur est survenue, veuillez contacter le support'
        } else this.error = 'Problème de connectivité, vérifiez votre couverture réseau'
      }
    },
  },
}
