import { monthlyCaService } from '@/services/index'
import ChartContainer from '@/components/shared/charts/ChartContainer/ChartContainer.vue'

export default {
  name: 'MonthlyVolume',
  components: {
    ChartContainer,
  },
  props: ['chartId', 'legendChartIds', 'isFullscreenLegendEnabled', 'isFullscreenLegendButtonsAreaEnabled'],
  computed: {
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns
    },
    volumeUnit() {
      return this.$store.getters.getVolumeUnit
    },
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    chartTitle() {
      return this.$store.getters.getVolumeUnitName
    },
    chartOptions() {
      const currentCampaignStartingYear = this.$store.getters.getCurrentCampaignObject.startingYear
      const currentCampaignStartingMonth = this.$store.getters.getCurrentCampaignObject.startingMonth
      const plotBandsOptions = monthlyCaService.getPlotBandsOptions(currentCampaignStartingYear, currentCampaignStartingMonth)
      const initialOptions = monthlyCaService.getColumnChartOptions(this.volumeUnit, this.supplierName)
      return Object.assign(initialOptions, { xAxis: { ...initialOptions.xAxis, plotBands: plotBandsOptions } })
    },
    chartData() {
      const marketMonthlyVolumes = this.$store.getters.getMarketMonthlyVolumes
      const supplierMonthlyVolumes = this.$store.getters.getSupplierMonthlyVolumes
      const campaigns = {
        current: this.$store.getters.getCurrentCampaignObject.toString(),
        previous: this.$store.getters.getCurrentCampaignObject.getPrevious().toString(),
      }

      return monthlyCaService.getColumnChartData(
        this.supplierName,
        marketMonthlyVolumes,
        supplierMonthlyVolumes,
        campaigns,
        this.volumeUnit,
        this.enabledCampaigns
      )
    },
  },
}
