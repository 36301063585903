import { GtmSupport as GtmPlugin, loadScript } from '@gtm-support/core'

const gtmPlugin = {
  install(Vue, options) {
    if (!options?.gtmOptions) return

    const { gtmOptions } = options
    if (!gtmOptions) {
      throw new Error('Options is missing')
    }
    const gtmPlugin = new GtmPlugin(gtmOptions)
    if (gtmPlugin.options.enabled && gtmPlugin.options.loadScript) {
      if (typeof gtmOptions.id === 'string') {
        loadScript(gtmOptions.id, gtmOptions)
      }
    }

    Vue.prototype.$gtmPlugin = gtmPlugin
  },
}

export default gtmPlugin
