const cumulativeDataFormatterService = {
  sumVolumesDayByDay(seriesData, startDate, endDate) {
    if (seriesData.length === 0) {
      return seriesData // NOTE: in case we got no data from our API for this particular serie
    }
    let seriesIndex = 0
    const currentValue = { ca: 0, ha: 0, dose: 0 }
    let currentDate = new Date(startDate)
    const result = []

    const isSameDay = (date1, date2) =>
      date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()

    while (currentDate <= endDate) {
      const seriesPoint = seriesData[seriesIndex]
      const seriesPointDate = seriesPoint ? new Date(seriesPoint.day) : undefined
      if (seriesPointDate && seriesPointDate < startDate) seriesIndex += 1
      else {
        if (seriesPointDate && isSameDay(seriesPointDate, currentDate)) {
          currentValue.ca = currentValue.ca + seriesPoint.ca
          currentValue.ha = currentValue.ha + seriesPoint.ha
          currentValue.dose = currentValue.dose + seriesPoint.dose
          seriesIndex += 1
        }
        result.push({ day: currentDate.getTime(), ...currentValue })
        currentDate = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24)
      }
    }
    return result
  },
  formatDataChart(data, volumeUnit, isPrevious) {
    return data.map((dailyVolume) => {
      const date = new Date(dailyVolume.day)
      return {
        x: isPrevious ? new Date(Date.UTC(date.getFullYear() + 1, date.getMonth(), date.getDate())).getTime() : dailyVolume.day,
        y: dailyVolume[volumeUnit],
      }
    })
  },
}

export default cumulativeDataFormatterService
