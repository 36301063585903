import Vue from 'vue'

const state = {
  dataBillingStatusSwitch: 'billed',
  unitSwitches: {
    SCOR: {
      phyto: {
        isCurrency: localStorage.getItem('phytoSwitchIsCurrencySCOR') === 'true',
      },
      seeds: {
        isCurrency: localStorage.getItem('seedsSwitchIsCurrencySCOR') === 'true',
      },
    },
    CHALLENGE: {
      phyto: {
        isCurrency: localStorage.getItem('phytoSwitchIsCurrencyCHALLENGE') === 'true',
      },
      seeds: {
        isCurrency: true,
      },
    },
    GALAXY: {
      phyto: {
        isCurrency: localStorage.getItem('phytoSwitchIsCurrencyGALAXY') === 'true',
      },
      seeds: {
        isCurrency: localStorage.getItem('seedsSwitchIsCurrencyGALAXY') === 'true',
      },
    },
  },
  campaignSelections: JSON.parse(localStorage.getItem('campaignSelections')) || {},
  lastConfigSupplierSummary: {
    phyto: {
      column: localStorage.getItem('configSupplierSummaryPhytoColumn') || 'market',
      paginationDesc: localStorage.getItem('configSupplierSummaryPhytoPagination') === 'true',
      groupTabVisible: localStorage.getItem('configSupplierSummaryPhytoGroupTabVisible') === 'true',
    },
    seeds: {
      column: localStorage.getItem('configSupplierSummarySeedsColumn') || 'market',
      paginationDesc: localStorage.getItem('configSupplierSummarySeedsPagination') === 'true',
      groupTabVisible: localStorage.getItem('configSupplierSummarySeedsGroupTabVisible') === 'true',
    },
  },
  lastConfigProductList: {
    column: localStorage.getItem('configProductListColumn') || 'isOwnProduct',
    paginationDesc: localStorage.getItem('configProductListPagination') !== 'false',
    nbClickSameColumn: Number(localStorage.getItem('configProductListNbClicks')) || 0,
  },
}

const getters = {
  getDataBillingStatusSwitch: (state) => state.dataBillingStatusSwitch,
  isCurrencyDisabled: (state) => state.dataBillingStatusSwitch === 'ordered',
  getApiFormatBillingStatus: (state) => {
    if (state.dataBillingStatusSwitch === 'billed') return 'facture'
    else if (state.dataBillingStatusSwitch === 'ordered') return 'commande'
    else throw new Error('Unhandled billing status ' + state.dataBillingStatusSwitch)
  },
  getLastConfigProductList: (state) => state.lastConfigProductList,
  getLastConfigSupplierSummary: (state) => state.lastConfigSupplierSummary,
  getVolumeUnitSwitchIsCurrency: (state) => (marketSegmentation, phytoOrSeeds) => {
    return state.unitSwitches[marketSegmentation][phytoOrSeeds].isCurrency
  },
  getVolumeUnit: (state, getters, rootState) => {
    const marketSegmentIsSeed = rootState.selectedMarketSegmentIsSeed
    const marketSegmentationSource = rootState.selectedMarketSegmentSource

    if (marketSegmentationSource.length === 0) {
      return 'ca'
    }
    return marketSegmentIsSeed
      ? state.unitSwitches[marketSegmentationSource].seeds.isCurrency
        ? 'ca'
        : 'dose'
      : state.unitSwitches[marketSegmentationSource].phyto.isCurrency
        ? 'ca'
        : 'ha'
  },
  getVolumeUnitName: (state, getters, rootState) => {
    const marketSegmentIsSeed = rootState.selectedMarketSegmentIsSeed
    const marketSegmentationSource = rootState.selectedMarketSegmentSource

    if (marketSegmentationSource.length === 0) {
      return ''
    }

    return marketSegmentIsSeed
      ? state.unitSwitches[marketSegmentationSource].seeds.isCurrency
        ? "Chiffre d'affaires"
        : 'Dose'
      : state.unitSwitches[marketSegmentationSource].phyto.isCurrency
        ? "Chiffre d'affaires"
        : 'Hectare'
  },
  getCampaignSelections: (state) => state.campaignSelections,
  getEnabledCampaigns: (state) =>
    Object.keys(state.campaignSelections)
      .filter((campaign) => state.campaignSelections[campaign].checked)
      .map((campaign) => state.campaignSelections[campaign].value),
}

const mutations = {
  setVolumeUnitSwitchIsCurrency(state, { marketSegmentation, phytoOrSeeds, newSwitchState }) {
    state.unitSwitches[marketSegmentation][phytoOrSeeds] = {
      ...state.unitSwitches[marketSegmentation][phytoOrSeeds],
      isCurrency: newSwitchState,
    }
    const localStorageItem = `${phytoOrSeeds}SwitchIsCurrency${marketSegmentation}`
    localStorage.setItem(localStorageItem, true)
  },
  setCampaignSelection(state, checkboxKey) {
    Vue.set(
      state.campaignSelections,
      checkboxKey,
      Object.assign({}, state.campaignSelections[checkboxKey], { checked: !state.campaignSelections[checkboxKey].checked })
    )
    localStorage.setItem('campaignSelections', JSON.stringify(state.campaignSelections))
  },
  setCampaignSelections(state, campaigns) {
    if (!Object.keys(state.campaignSelections).length) {
      state.campaignSelections = campaigns
    } else {
      Object.keys(campaigns).forEach((campaign) => {
        state.campaignSelections[campaign] = {
          ...campaigns[campaign],
          checked: state.campaignSelections[campaign].checked,
        }
      })
    }
    localStorage.setItem('campaignSelections', JSON.stringify(state.campaignSelections))
  },
  setSupplierCumulativeVolumes(state, supplierCumulativeVolumes) {
    state.cumulativeVolumes = {
      ...state.cumulativeVolumes,
      supplier: supplierCumulativeVolumes,
    }
  },
  setSeedsLastConfigSupplierSummary(state, lastConfigSupplierSummary) {
    state.lastConfigSupplierSummary = {
      ...state.lastConfigSupplierSummary,
      seeds: lastConfigSupplierSummary,
    }
    localStorage.setItem('configSupplierSummarySeedsColumn', lastConfigSupplierSummary.column)
    localStorage.setItem('configSupplierSummarySeedsPagination', lastConfigSupplierSummary.paginationDesc)
    localStorage.setItem('configSupplierSummarySeedsGroupTabVisible', lastConfigSupplierSummary.groupTabVisible)
  },
  setPhytoLastConfigSupplierSummary(state, lastConfigSupplierSummary) {
    state.lastConfigSupplierSummary = {
      ...state.lastConfigSupplierSummary,
      phyto: lastConfigSupplierSummary,
    }
    localStorage.setItem('configSupplierSummaryPhytoColumn', lastConfigSupplierSummary.column)
    localStorage.setItem('configSupplierSummaryPhytoPagination', lastConfigSupplierSummary.paginationDesc)
    localStorage.setItem('configSupplierSummaryPhytoGroupTabVisible', lastConfigSupplierSummary.groupTabVisible)
  },
  setlastConfigProductList(state, lastConfigProductList) {
    state.lastConfigProductList.column = lastConfigProductList.column
    state.lastConfigProductList.paginationDesc = lastConfigProductList.paginationDesc
    localStorage.setItem('configProductListColumn', lastConfigProductList.column)
    localStorage.setItem('configProductListPagination', lastConfigProductList.paginationDesc)
    localStorage.setItem('configProductListNbClicks', lastConfigProductList.nbClickSameColumn)
  },
  setDataBillingStatusSwitch(state, newDataBillingStatus) {
    state.dataBillingStatusSwitch = newDataBillingStatus
  },
}

const actions = {
  changeDataBillingStatus({ commit }, newDataBillingStatus) {
    commit('setDataBillingStatusSwitch', newDataBillingStatus)
    if (newDataBillingStatus === 'ordered') {
      commit('setVolumeUnitSwitchIsCurrency', { marketSegmentation: 'SCOR', phytoOrSeeds: 'phyto', newSwitchState: false })
      commit('setVolumeUnitSwitchIsCurrency', { marketSegmentation: 'SCOR', phytoOrSeeds: 'seeds', newSwitchState: false })
    }
  },
}

export default {
  getters,
  actions,
  mutations,
  state,
}
