import dateFormatterService from '@/services/dateFormatter.service'
import TrackingService from '../../../services/tracking.service'

export default {
  name: 'PeriodSelector',
  data() {
    return {
      currentMonth: null,
      currentYear: null,
      dropdownProps: {
        maxHeight: 310,
        nudgeBottom: 42,
      },
      availablePeriods: [
        { text: 'Janvier', value: 1 },
        { text: 'Février', value: 2 },
        { text: 'Mars', value: 3 },
        { text: 'Avril', value: 4 },
        { text: 'Mai', value: 5 },
        { text: 'Juin', value: 6 },
        { text: 'Juillet', value: 7 },
        { text: 'Août', value: 8 },
        { text: 'Septembre', value: 9 },
        { text: 'Octobre', value: 10 },
        { text: 'Novembre', value: 11 },
        { text: 'Décembre', value: 12 },
      ],
    }
  },
  computed: {
    startingMonth: {
      get() {
        return this.$store.getters.getSelectedStartingMonth
      },
      set(newStartingMonth) {
        this.$store.commit('setCampaignStartingMonth', newStartingMonth)
        TrackingService.trackEvent('click_dropdown_campaign', {
          additional_data: this.availablePeriods.find((p) => p.value === newStartingMonth).text,
        })
      },
    },
    dropdownPeriodItems() {
      const computeAdditionnalData = (periodItem) => {
        const additionnalData = { year: this.getYearForGivenMonth(periodItem.value), text: this.formatDropdownText(periodItem) }
        return Object.assign(periodItem, additionnalData)
      }
      return this.availablePeriods.map(computeAdditionnalData).sort(this.sortDropdown)
    },
  },
  methods: {
    getYearForGivenMonth(periodValue) {
      return periodValue > this.currentMonth ? this.currentYear - 1 : this.currentYear
    },
    formatDropdownText(periodItem) {
      return periodItem.text + ' ' + this.getYearForGivenMonth(periodItem.value)
    },
    sortDropdown(firstItem, secondItem) {
      const firstItemDateTime = new Date(firstItem.year, firstItem.value - 1)
      const secondItemDateTime = new Date(secondItem.year, secondItem.value - 1)
      return firstItemDateTime - secondItemDateTime
    },
  },
  beforeMount() {
    const now = new Date()
    this.currentYear = now.getFullYear()
    this.currentMonth = dateFormatterService.getMonthFromDate(now)
  },
}
