import dateFormatterService from './../dateFormatter.service'
import tooltipService from './tooltip.service'
import graphUtilityService from '../graphUtility.service'

const productsDistributionService = {
  formatData(product, volumeMeasurementUnit, marketSegmentCA, productDetails, campaign, isPreviousCampaign) {
    return {
      x: isPreviousCampaign ? 1 : 0,
      y: this.computeProductDistributionRate(marketSegmentCA, productDetails, volumeMeasurementUnit),
    }
  },

  computeMarketSegmentCA(marketSegmentCAData, volumeMeasurementUnit) {
    return marketSegmentCAData && marketSegmentCAData[marketSegmentCAData.length - 1]
      ? marketSegmentCAData[marketSegmentCAData.length - 1][volumeMeasurementUnit]
      : 0
  },

  computeProductDistributionRate(marketSegmentCA, productDetails, volumeMeasurementUnit) {
    const productCA =
      productDetails && productDetails[productDetails.length - 1] ? productDetails[productDetails.length - 1][volumeMeasurementUnit] : 0
    return marketSegmentCA && marketSegmentCA !== 0 ? Math.max((productCA / marketSegmentCA) * 100.0, 0) : 0
  },

  getBarChartData(marketSegmentCAData, productsCAData, campaigns, volumeUnit, visibleCampaigns) {
    const productsNameCurrent = Object.keys(productsCAData.current)
    const productsNamePrevious = Object.keys(productsCAData.previous)
    let index = -1
    const legendItems = productsNameCurrent.map((product) => {
      index++
      return graphUtilityService.formatLegendItem(product, graphUtilityService.getProductsFormattedColor(index), true, true)
    })
    productsNamePrevious
      .filter((previousProductName) => productsNameCurrent.indexOf(previousProductName) === -1)
      .forEach((product) => {
        index++
        legendItems.push(graphUtilityService.formatLegendItem(product, graphUtilityService.getProductsFormattedColor(index), true, false))
      })

    const marketSegmentCurrentCAData = marketSegmentCAData.current
    const marketSegmentPreviousCAData = marketSegmentCAData.previous
    const productsCurrentCAData = productsCAData.current
    const productsPreviousCAData = productsCAData.previous
    const currentCampaign = dateFormatterService.formatCampaign(campaigns.current)
    const previousCampaign = dateFormatterService.formatCampaign(campaigns.previous)
    const series = []
    if (marketSegmentCurrentCAData && productsCurrentCAData) {
      const volumeMeasurementUnit = volumeUnit === 'ca' ? 'ca' : volumeUnit === 'ha' ? 'ha' : 'dose'
      const marketSegmentCACurrent = this.computeMarketSegmentCA(marketSegmentCurrentCAData, volumeMeasurementUnit)
      const marketSegmentCAPrevious = this.computeMarketSegmentCA(marketSegmentPreviousCAData, volumeMeasurementUnit)
      const seriesDataMapFuncWithComparison = (product) => {
        series.push({
          id: product.id,
          name: product.name,
          visible: visibleCampaigns.includes(campaigns.current),
          data: [
            this.formatData(
              product,
              volumeMeasurementUnit,
              marketSegmentCACurrent,
              productsCurrentCAData[product.name],
              currentCampaign,
              false
            ),
          ],
          perimeter: product.name,
          color: product.color.full,
          borderColor: product.color.full,
          campaign: 'current',
          ...graphUtilityService.CURRENT_CAMPAIGN_SERIE_LINE_STYLE,
        })
        series.push({
          name: product.name,
          linkedTo: product.id,
          visible: visibleCampaigns.includes(campaigns.previous),
          data: [
            this.formatData(
              product,
              volumeMeasurementUnit,
              marketSegmentCAPrevious,
              productsPreviousCAData[product.name],
              previousCampaign,
              true
            ),
          ],
          perimeter: product.name,
          color: product.color.opaque,
          borderColor: product.color.full,
          campaign: 'previous',
          ...graphUtilityService.PREVIOUS_CAMPAIGN_SERIE_LINE_STYLE,
        })
      }

      legendItems.map(seriesDataMapFuncWithComparison)
    }

    return {
      series,
    }
  },

  getBarChartOptions(startCampaign, lastUpdateTime) {
    const date = { currentDate: lastUpdateTime, previousDate: dateFormatterService.getStringDateLastYear(lastUpdateTime) }

    return {
      chart: {
        type: 'bar',
        marginRight: 60,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      series: [],
      xAxis: {
        labels: {
          format: '<b>{value}</b>',
        },
        categories: [
          startCampaign + '-' + '<br/>' + date.currentDate,
          dateFormatterService.getStringDateLastYear(startCampaign) + '-' + '<br/>' + date.previousDate,
        ],
      },
      yAxis: {
        maxPadding: 0,
        minPadding: 10,
        reversedStacks: false,
        title: {
          align: 'high',
          reserveSpace: false,
          text: '<b>Part de marché (%)</b>',
          x: 40,
          y: 0,
        },
        labels: {
          formatter: function () {
            return this.value + '%'
          },
        },
      },
      tooltip: {
        split: true,
        useHTML: true,
        borderRadius: 4,
        borderWidth: 0,
        followPointer: true,
        shadow: true,
        formatter: function () {
          const currentPoint = this.point
          const linkedPoint =
            currentPoint.series.linkedSeries.length > 0
              ? currentPoint.series.linkedSeries[0].points[0]
              : currentPoint.series.linkedParent.points[0]
          const titles = {
            current: 'Actuel',
            previous: 'Il y a 1 an',
          }
          return tooltipService.getTooltipHTMLWithCustomTitle('bar', [currentPoint, linkedPoint], 'marketShare', titles)
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
    }
  },
}

export default productsDistributionService
