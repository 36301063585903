import Vue from 'vue'
import ToolTip from '@/components/shared/charts/ToolTip/ToolTip.vue'

const tooltipService = {
  getTooltipHTML(graphType, points, x, dataType, dateFormat, customTitles = {}, withMarketShare = false) {
    const ToolTipClass = Vue.extend(ToolTip)
    const toolTipInstance = new ToolTipClass({
      propsData: {
        points,
        x,
        graphType,
        dataType,
        dateFormat,
        customTitles,
        withMarketShare,
      },
    })
    toolTipInstance.$mount()
    return toolTipInstance.$el.outerHTML
  },
  getTooltipHTMLWithCustomTitle(graphType, points, dataType, customTitles) {
    return this.getTooltipHTML(graphType, points, 0, dataType, '', customTitles)
  },
  getTooltipHTMLWithMarketShare(graphType, points, x, dataType, dateFormat) {
    return this.getTooltipHTML(graphType, points, x, dataType, dateFormat, {}, true)
  },
}

export default tooltipService
