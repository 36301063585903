import { privateApiConnectorService } from '@/services'

const state = {
  sortedProductsList: [],
  suppliersCount: null,
}

const getters = {
  getSortedProductsList: (state) => state.sortedProductsList,
  getSuppliersCount: (state) => state.suppliersCount,
  getProductsCount: (state) => state.sortedProductsList.length,
}

const mutations = {
  setSortedProductsList(state, sortedProductsList) {
    state.sortedProductsList = sortedProductsList
  },
  setSuppliersCount(state, suppliersCount) {
    state.suppliersCount = suppliersCount
  },
}

const actions = {
  async updateReferentialData({ commit }, segmentId) {
    const productsResult = await privateApiConnectorService.getProductsListForSegment(segmentId)

    const sortedProductsList = productsResult.sort((product1, product2) => {
      if (product1.isOwnProduct && !product2.isOwnProduct) return -1
      if (!product1.isOwnProduct && product2.isOwnProduct) return 1

      if (product1.product < product2.product) return -1
      if (product1.product > product2.product) return 1
      return 0
    })
    commit('setSortedProductsList', sortedProductsList)
    const supplierCountResult = await privateApiConnectorService.getSupplierCountForSegment(segmentId)
    commit('setSuppliersCount', supplierCountResult.supplier_count)
  },
}

export default {
  getters,
  actions,
  mutations,
  state,
}
