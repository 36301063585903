import GenericCloseButton from '@/components/shared/popins/GenericCloseButton/GenericCloseButton.vue'

export default {
  name: 'Cookies',
  components: {
    GenericCloseButton,
  },
  data() {
    return {
      selectedSupplier: this.$store.getters.getSelectedSupplier,
    }
  },
  computed: {
    suppliersList() {
      return this.$store.getters.getSuppliersList
    },
  },
  methods: {
    async submitForm() {
      await this.$store.dispatch('updateSupplierId', this.selectedSupplier)
      this.closePopin()
      this.$router.go(0)
    },
    closePopin() {
      this.$parent.$parent.$emit('close-popin')
    },
  },
}
