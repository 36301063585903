import { evolutionMarketShareService } from '@/services/index'
import ChartContainer from '@/components/shared/charts/ChartContainer/ChartContainer.vue'
import format from 'date-fns/format'

export default {
  name: 'EvolutionMarketShareSupplier',
  components: {
    ChartContainer,
  },
  props: ['chartId', 'legendChartIds', 'isFullscreenLegendEnabled', 'isFullscreenLegendButtonsAreaEnabled'],
  computed: {
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns
    },
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    chartOptions() {
      return evolutionMarketShareService.getLineChartOptions()
    },
    volumeUnit() {
      return this.$store.getters.getVolumeUnit
    },
    chartData() {
      const marketCumulativeVolumes = this.$store.getters.getMarketCumulativeVolumes
      const supplierCumulativeVolumes = this.$store.getters.getSupplierCumulativeVolumes

      const campaigns = {
        current: this.$store.getters.getCurrentCampaignObject.toString(),
        previous: this.$store.getters.getCurrentCampaignObject.getPrevious().toString(),
      }
      const currentCampaignStartingDate = format(this.$store.getters.getCurrentCampaignObject.getStartingDate(), 'YYYY-MM-DD')

      return evolutionMarketShareService.getLineChartData(
        this.supplierName,
        marketCumulativeVolumes,
        supplierCumulativeVolumes,
        campaigns,
        this.volumeUnit,
        this.enabledCampaigns,
        currentCampaignStartingDate,
        new Date()
      )
    },
  },
}
