import Highcharts from 'highcharts'
import currencyFormatterService from '@/services/currencyFormatter.service'
import store from '@/store/index'
import graphUtilityService from '@/services/graphUtility.service'

export default {
  name: 'ToolTip',
  props: {
    points: Array,
    x: Number,
    graphType: String,
    dataType: String,
    dateFormat: {
      type: String,
      default: '%d/%m/%y',
    },
    customTitles: {
      type: Object,
      default: function () {
        return {}
      },
    },
    withMarketShare: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPerimeterFullName(perimeter) {
      return store.getters.getFullNamePerimeter(perimeter)
    },
    formatPoint(point) {
      if (point === undefined) return '--'
      else {
        if (this.dataType === 'marketShare') return currencyFormatterService.formatMarketShare(point.y)
        else if (this.dataType === 'ca') return currencyFormatterService.formatCA(point.y)
        else if (this.dataType === 'ha') return currencyFormatterService.formatHa(point.y)
        else if (this.dataType === 'dose') return currencyFormatterService.formatDose(point.y)
        else return point.y
      }
    },
    getColor(point) {
      if (point === undefined || point.series === undefined || point.series.color === undefined) return 'rgba(136, 136, 136, 1)'
      else return point.series.color
    },
    getColorWithOpacity(point) {
      if (point === undefined || point.series === undefined || point.series.color === undefined) return 'rgba(136, 136, 136, .2)'
      else return graphUtilityService.formatRGBColorWithOpacity(point.series.color)
    },
    getColorWithoutOpacity(point) {
      if (point === undefined || point.series === undefined || point.series.color === undefined) return 'rgba(136, 136, 136, 1)'
      else return graphUtilityService.formatRGBColorWithoutOpacity(point.series.color)
    },
    containsPoint(point) {
      return !(point === undefined || point.series === undefined || point.series.color === undefined)
    },
    computeMarketShare(global, part) {
      if (!global || !part || global === 0) return null
      else return (part / global) * 100
    },
  },
  computed: {
    isLineChart() {
      return this.graphType === 'spline' || this.graphType === 'line'
    },
    sortedPointKeys() {
      const keys = Object.keys(this.pointsByCampaign)
      const campaignToCompare = this.containsCurrentData ? 'current' : 'previous'
      return keys.sort((key1, key2) => {
        const point1 = this.pointsByCampaign[key1][campaignToCompare]
        const point2 = this.pointsByCampaign[key2][campaignToCompare]
        if (point1 === undefined && point2 === undefined) return 0
        else if (point1 === undefined || key1 === 'supplier') return 1
        else if (point2 === undefined || key1 === 'market') return -1
        else return point2.y - point1.y
      })
    },
    containsCurrentData() {
      const keys = Object.keys(this.pointsByCampaign)
      if (keys.length === 0) return false
      else {
        return keys.some((key) => {
          return 'current' in this.pointsByCampaign[key]
        })
      }
    },
    containsPreviousData() {
      const keys = Object.keys(this.pointsByCampaign)
      if (keys.length === 0) return false
      else {
        return keys.some((key) => {
          return 'previous' in this.pointsByCampaign[key]
        })
      }
    },
    pointCurrentDate() {
      if (this.customTitles.current) return this.customTitles.current
      else return Highcharts.dateFormat(this.dateFormat, this.x)
    },
    pointPreviousDate() {
      if (this.customTitles.previous) return this.customTitles.previous
      else {
        const pointDate = new Date(this.x)
        pointDate.setFullYear(pointDate.getFullYear() - 1)
        return Highcharts.dateFormat(this.dateFormat, pointDate)
      }
    },
    pointsByCampaign() {
      return this.points.reduce(function (pointPerimeterAccumulator, point) {
        if (point.series) {
          const perimeter = point.series.options.perimeter
          const perimeterContent = pointPerimeterAccumulator[perimeter] || {}
          if (point.series.options.campaign === 'current') perimeterContent.current = point
          else if (point.series.options.campaign === 'previous') perimeterContent.previous = point
          pointPerimeterAccumulator[perimeter] = perimeterContent
        }
        return pointPerimeterAccumulator
      }, {})
    },
    previousMarketShare() {
      if (this.withMarketShare && this.containsPreviousData) {
        const globalPrevious = this.pointsByCampaign[this.sortedPointKeys[0]]
          ? this.pointsByCampaign[this.sortedPointKeys[0]].previous
          : null
        const globalValue = globalPrevious ? globalPrevious.y : null
        const partPrevious = this.pointsByCampaign[this.sortedPointKeys[1]] ? this.pointsByCampaign[this.sortedPointKeys[1]].previous : null
        const partValue = partPrevious ? partPrevious.y : null
        return this.computeMarketShare(globalValue, partValue)
      } else return null
    },
    currentMarketShare() {
      if (this.withMarketShare && this.containsCurrentData) {
        const globalCurrent = this.pointsByCampaign[this.sortedPointKeys[0]] ? this.pointsByCampaign[this.sortedPointKeys[0]].current : null
        const globalValue = globalCurrent ? globalCurrent.y : null
        const partCurrent = this.pointsByCampaign[this.sortedPointKeys[1]] ? this.pointsByCampaign[this.sortedPointKeys[1]].current : null
        const partValue = partCurrent ? partCurrent.y : null
        return this.computeMarketShare(globalValue, partValue)
      } else return null
    },
  },
}
