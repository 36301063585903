import auth0 from 'auth0-js'
import axios from 'axios'
import router from '../router'
import store from '../store/index'
import { apiConnectorService } from '../services'

window.env = window.env || {
  AUTH0_DOMAIN_NAME: 'localhost',
  AUTH0_CLIENT_ID: 'some_id',
  AUTH0_AUDIENCE: 'localhost',
}

const LOCAL_STORAGE_ID_TOKEN_KEY = 'id_token'
const LOCAL_STORAGE_ACCESS_TOKEN_KEY = 'access_token'
const LOCAL_STORAGE_EXPIRES_AT_KEY = 'expires_at'
const ROUTE_HOME_PAGE = `${window.env.SERVER_URL}`
const ROUTE_PRIVATE_PAGE = 'home'

const AUTH0_DOMAIN = window.env.AUTH0_DOMAIN_NAME
const AUTH0_CLIENT_ID = window.env.AUTH0_CLIENT_ID
const AUTH0_AUDIENCE = window.env.AUTH0_AUDIENCE
const AUTH0_ACCESS_TOKEN_CALLBACK_URL = `${window.env.SERVER_URL}/auth0/access-token/callback`

const AUTH0_RESET_PASSWORD_URL = `https://${window.env.AUTH0_DOMAIN_NAME}/dbconnections/change_password`

export default class AuthService {
  constructor() {
    this.logout = this.logout.bind(this)
  }

  auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    audience: AUTH0_AUDIENCE,
    redirectUri: AUTH0_ACCESS_TOKEN_CALLBACK_URL,
    responseType: 'token id_token',
    scope: 'openid',
  })

  async authenticate({ email, password }) {
    const response = await apiConnectorService.post('/users/token', { email, password, roles: ['portal-supplier-user'] })
    AuthService.setSession(response)
    router.replace({ name: ROUTE_PRIVATE_PAGE })
    return true
  }

  static setSession(authResult) {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(authResult.expires_in * 1000 + new Date().getTime())
    localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, authResult.access_token)
    localStorage.setItem(LOCAL_STORAGE_ID_TOKEN_KEY, authResult.id_token)
    localStorage.setItem(LOCAL_STORAGE_EXPIRES_AT_KEY, expiresAt)
    store.commit('setIsAuthenticated', true)
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem(LOCAL_STORAGE_EXPIRES_AT_KEY))
    return new Date().getTime() < expiresAt
  }

  logout() {
    this.cleanAuthenticationToken()
    this.auth0.logout({
      clientID: AUTH0_CLIENT_ID,
      returnTo: ROUTE_HOME_PAGE,
    })
  }

  cleanAuthenticationToken() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY)
    localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN_KEY)
    localStorage.removeItem(LOCAL_STORAGE_EXPIRES_AT_KEY)
  }

  async resetPassword({ email }) {
    const response = await axios.post(AUTH0_RESET_PASSWORD_URL, {
      client_id: window.env.AUTH0_CLIENT_ID,
      connection: 'Username-Password-Authentication',
      email,
    })
    return response
  }
}
