import { cumulativeCaService } from '@/services/index'
import ChartContainer from '@/components/shared/charts/ChartContainer/ChartContainer.vue'

export default {
  name: 'CumulativeVolumeSupplier',
  components: {
    ChartContainer,
  },
  props: ['chartId', 'legendChartIds', 'isFullscreenLegendEnabled', 'isFullscreenLegendButtonsAreaEnabled'],
  computed: {
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns
    },
    volumeUnit() {
      return this.$store.getters.getVolumeUnit
    },
    supplierName() {
      return this.$store.state.connectedSupplierName
    },
    chartTitle() {
      return this.$store.getters.getVolumeUnitName
    },
    chartOptions() {
      return cumulativeCaService.getLineChartOptions(this.volumeUnit, this.supplierName)
    },
    chartData() {
      const marketCumulativeVolumes = this.$store.getters.getMarketCumulativeVolumes
      const supplierCumulativeVolumes = this.$store.getters.getSupplierCumulativeVolumes
      const currentCampaign = this.$store.getters.getCurrentCampaignObject
      const campaigns = {
        current: currentCampaign.toString(),
        previous: currentCampaign.getPrevious().toString(),
      }

      return cumulativeCaService.getLineChartData(
        this.supplierName,
        marketCumulativeVolumes,
        supplierCumulativeVolumes,
        campaigns,
        this.volumeUnit,
        this.enabledCampaigns
      )
    },
  },
}
