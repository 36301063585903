import SummaryTitleAndControls from '@/components/SupplierSummary/SummaryTitleAndControls/SummaryTitleAndControls.vue'
import IndividualMarketTable from '@/components/SupplierSummary/IndividualMarketTable/IndividualMarketTable.vue'
import MarketGroupTable from '@/components/SupplierSummary/MarketGroupTable/MarketGroupTable.vue'

export default {
  name: 'SupplierSummary',
  components: {
    IndividualMarketTable,
    MarketGroupTable,
    SummaryTitleAndControls,
  },
  data() {
    return {
      isFetchingSummary: false,
      stickyOffsetTopbar: {
        top: -9,
        bottom: 143,
      },
      stickyOffsetTable: {
        top: 130,
        bottom: 55,
      },
      groupTableViewSwitch: {
        phyto: this.$store.getters.getLastConfigSupplierSummary.phyto.groupTabVisible,
        seeds: this.$store.getters.getLastConfigSupplierSummary.seeds.groupTabVisible,
      },
    }
  },
  computed: {
    campaignStartingMonth() {
      return this.$store.getters.getSelectedStartingMonth
    },
    showPhyto() {
      return this.supplierHasSCORPhytoSummary || !this.supplierHasSeedsSummary
    },
    supplierHasSCORPhytoSummary() {
      return (
        this.isSummaryLoaded &&
        (Object.keys(this.$store.getters.getPhytoMarketGroupSupplierSummary).length > 0 ||
          Object.keys(this.$store.getters.getPhytoIndividualMarketSupplierSummary('SCOR')).length > 0)
      )
    },
    supplierHasSCORSeedsSummary() {
      return (
        this.isSummaryLoaded &&
        (Object.keys(this.$store.getters.getSeedsMarketGroupSupplierSummary).length > 0 ||
          Object.keys(this.$store.getters.getSeedsIndividualMarketSupplierSummary('SCOR')).length > 0)
      )
    },
    supplierHasCHALLENGEPhytoSummary() {
      return this.isSummaryLoaded && Object.keys(this.$store.getters.getPhytoIndividualMarketSupplierSummary('CHALLENGE')).length > 0
    },
    supplierHasCHALLENGESeedsSummary() {
      return this.isSummaryLoaded && Object.keys(this.$store.getters.getSeedsIndividualMarketSupplierSummary('CHALLENGE')).length > 0
    },
    supplierHasGALAXYSummary() {
      return this.isSummaryLoaded && Object.keys(this.$store.getters.getPhytoIndividualMarketSupplierSummary('GALAXY')).length > 0
    },
    isSummaryLoaded() {
      return Object.keys(this.$store.getters.getFullSupplierSummary).length > 0
    },
    isNoMarketSegmentAuthorized() {
      return ['SCOR', 'CHALLENGE', 'GALAXY'].reduce((accumulator, segmentationSource) => {
        return (
          accumulator &&
          JSON.stringify(this.$store.getters.getSupplierSummaryByMarketSegmentationSource(segmentationSource).phyto) ===
            JSON.stringify({}) &&
          JSON.stringify(this.$store.getters.getSupplierSummaryByMarketSegmentationSource(segmentationSource).seeds) === JSON.stringify({})
        )
      }, true)
    },
    isFailDuringGettingSummary() {
      return this.$store.getters.getIsErrorLoadingSummary
    },
  },
  watch: {
    async campaignStartingMonth(newCampaignStartingMonth) {
      this.isFetchingSummary = true
      await this.$store.dispatch('fetchSupplierSummary', newCampaignStartingMonth)
      this.isFetchingSummary = false
    },
    getLastConfigSupplierSummary: {
      handler: function (lastConfigSupplierSummary) {
        if (lastConfigSupplierSummary) {
          this.groupTableViewSwitch = {
            phyto: this.$store.getters.getLastConfigSupplierSummary.phyto.groupTabVisible,
            seeds: this.$store.getters.getLastConfigSupplierSummary.seeds.groupTabVisible,
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSummaryViewChange(marketType) {
      const lastConfig = Object.assign({}, this.$store.getters.getLastConfigSupplierSummary[marketType])
      this.groupTableViewSwitch[marketType] = !this.groupTableViewSwitch[marketType]
      lastConfig.groupTabVisible = this.groupTableViewSwitch[marketType]
      if (marketType === 'seeds') {
        this.$store.commit('setSeedsLastConfigSupplierSummary', lastConfig)
      } else {
        this.$store.commit('setPhytoLastConfigSupplierSummary', lastConfig)
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch('resetMarketSegmentSelection')
    const startingMonth = this.$store.getters.getSelectedStartingMonth
    await this.$store.dispatch('fetchSupplierSummary', startingMonth)
  },
}
