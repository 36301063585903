import '@babel/polyfill'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store/index'
import Highcharts from 'highcharts'
import { dateFormatterService, currencyFormatterService } from './services'
import Sticky from 'vue-sticky-directive'
import VueGtag from 'vue-gtag'
import gtmPlugin from './plugins/analytics/gtm-plugin'
import TrackingService from './services/tracking.service'

Vue.config.productionTip = false

Highcharts.setOptions({
  lang: {
    noData:
      '<table class="v-datatable v-table theme--light"><tr><td colspan="2"><div class="v-alert error"><i aria-hidden="true" class="v-icon material-icons theme--light v-alert__icon">warning</i><div>Veuillez sélectionner au moins une donnée à analyser.</div></div></td></tr></table>',
    shortMonths: ['jan.', 'fév.', 'mar.', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sep.', 'oct.', 'nov.', 'déc.'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  },
  noData: {
    useHTML: true,
  },
})

// Init analytics
const analyticsId = (window.env && window.env.G_ANALYTICS_ID) || false
if (analyticsId) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: analyticsId,
      },
    },
    router
  )
}

// Init GTM
const gtmId = (window.env && window.env.GTM_ID) || false
if (gtmId) {
  const gtmOptions = {
    id: gtmId,
    defer: false,
    debug: false,
  }
  Vue.use(gtmPlugin, { gtmOptions })
}

TrackingService.setGtmPlugin(Vue.prototype.$gtmPlugin)

Vue.filter('formatValue', (value, volumeUnit) => {
  return currencyFormatterService.formatValue(value, volumeUnit)
})

Vue.filter('formatDate', (date) => {
  return dateFormatterService.formatDate(date)
})

Vue.filter('formatCA', (ca) => {
  return currencyFormatterService.formatCA(ca)
})

Vue.filter('formatHa', (ha) => {
  return currencyFormatterService.formatHa(ha)
})

Vue.filter('formatEvolution', (evolution) => {
  return currencyFormatterService.formatEvolution(evolution)
})

Vue.filter('formatMarketShare', (marketShare) => {
  return currencyFormatterService.formatMarketShare(marketShare)
})

Vue.filter('formatMarketShareEvolution', (marketShareEvolution) => {
  return currencyFormatterService.formatMarketShareEvolution(marketShareEvolution)
})

Vue.use(Sticky)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
document.title = window.env.APP_PROVIDER === 'PPA' ? 'Polposition' : 'Polposition | ' + window.env.APP_PROVIDER_DISPLAYED_NAME
