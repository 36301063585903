export default class Campaign {
  constructor(startingYear, startingMonth) {
    this.startingYear = parseInt(startingYear)
    this.startingMonth = parseInt(startingMonth)
  }

  static fromDate(date, startingMonth) {
    const startingYear = date.getMonth() + 1 >= startingMonth ? date.getFullYear() : date.getFullYear() - 1
    return new Campaign(startingYear, startingMonth)
  }

  getStartingDate() {
    return new Date(Date.UTC(this.startingYear, this.startingMonth - 1))
  }

  getEndDate() {
    const resultDate = this.getStartingDate()
    resultDate.setFullYear(resultDate.getFullYear() + 1)
    resultDate.setDate(resultDate.getDate() - 1)
    return resultDate
  }

  getPrevious() {
    return new Campaign(this.startingYear - 1, this.startingMonth)
  }

  toString() {
    return this.startingYear + '-' + (this.startingYear + 1)
  }
}
