import MarketSegmentTitle from './MarketSegmentTitle/MarketSegmentTitle.vue'
import CurrencyAreaSwitch from '@/components/shared/switchButtons/CurrencyAreaSwitch/CurrencyAreaSwitch.vue'
import DataBillingStatusSwitch from '@/components/shared/switchButtons/DataBillingStatusSwitch/DataBillingStatusSwitch.vue'
import PeriodSelector from '@/components/shared/PeriodSelector/PeriodSelector.vue'
import CampaignSelection from '@/components/shared/checkboxSelectors/CampaignSelection/CampaignSelection.vue'

export default {
  name: 'MarketSegmentTitleAndControls',
  components: {
    MarketSegmentTitle,
    CurrencyAreaSwitch,
    PeriodSelector,
    DataBillingStatusSwitch,
    CampaignSelection,
  },
  computed: {
    marketSegmentationSource() {
      return this.$store.getters.getSelectedMarketSegmentSource
    },
    marketSegmentName() {
      return this.$store.getters.getSelectedMarketSegmentName
    },
    marketType() {
      return this.$store.getters.getSelectedMarketSegmentIsSeed ? 'seeds' : 'phyto'
    },
    disableNonCurrencyUnit() {
      return this.marketType === 'seeds' && this.marketSegmentationSource === 'CHALLENGE'
    },
  },
}
