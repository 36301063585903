import ChartLegend from '@/components/MarketSegment/MarketSegmentDataViz/ChartLegend/ChartLegend.vue'
import GenericCloseButton from '@/components/shared/popins/GenericCloseButton/GenericCloseButton.vue'
import Highcharts from 'highcharts'
require('highcharts/modules/data')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)

export default {
  name: 'ChartFullscreen',
  components: {
    ChartLegend,
    GenericCloseButton,
  },
  props: {
    title: {
      type: String,
    },
    chartId: {
      type: String,
    },
    legendChartIds: {
      type: Array,
    },
    isLegendButtonsAreaEnabled: {
      type: Boolean,
    },
    isLegendDisplayed: {
      type: Boolean,
    },
    chartOptions: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: undefined,
    }
  },
  computed: {
    fullscreenChartId() {
      return 'fullscreen-' + this.chartId
    },
    enabledPerimeters() {
      return this.$store.getters.getEnabledSeries[this.chartId] || {}
    },
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns || []
    },
    previousCampaign() {
      return this.$store.getters.getCurrentCampaignObject.getPrevious().toString()
    },
    currentCampaign() {
      return this.$store.getters.getCurrentCampaignObject.toString()
    },
    showLegend() {
      return this.isLegendDisplayed
    },
  },
  watch: {
    chartData(newChartData) {
      const updatedSeries = newChartData.series.map((serie) => {
        const perimeter = serie.perimeter
        const campaign = serie.campaign
        serie.visible = this.shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign)
        return serie
      })
      this.chart.update({ series: updatedSeries })
    },
    chartOptions(newChartOptions) {
      this.chart.update(newChartOptions)
    },
    enabledPerimeters() {
      this.toggleSeriesVisibility()
    },
  },
  methods: {
    toggleSeriesVisibility() {
      this.chart.series.forEach((serie) => {
        if (!this.shouldBeVisible(serie) && serie.visible) serie.hide()
        else if (this.shouldBeVisible(serie) && !serie.visible) serie.show()
      })
      this.chart.yAxis.forEach((axis) => {
        if (!axis.hasVisibleSeries && axis.visible) axis.update({ visible: false })
        else if (axis.hasVisibleSeries && !axis.visible) axis.update({ visible: true })
      })
    },
    shouldBeVisible(serie) {
      const perimeter = serie.options.perimeter
      const campaign = serie.options.campaign
      return this.shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign)
    },
    shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign) {
      if (Object.keys(this.enabledPerimeters).includes(perimeter) && !this.enabledPerimeters[perimeter]) return false
      else if (campaign === 'previous' && !this.enabledCampaigns.includes(this.previousCampaign)) return false
      else if (campaign === 'current' && !this.enabledCampaigns.includes(this.currentCampaign)) return false
      else return true
    },
    closePopin() {
      this.$parent.$parent.$emit('close-popin')
    },
  },
  async mounted() {
    if (this.chartOptions) {
      const options = this.chartOptions
      if (this.chartData) {
        options.series = []
        this.chartData.series.forEach((serie) => {
          const perimeter = serie.perimeter
          const campaign = serie.campaign
          serie.visible = this.shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign)
          options.series.push(serie)
        })
      }
      this.chart = Highcharts.chart(this.fullscreenChartId, options)
      this.$store.commit('upsertChart', this.chart)
    }
  },
}
