import Campaign from '@/services/campaign'

const state = {
  currentCampaign: Campaign.fromDate(new Date(), localStorage.getItem('campaignStartingMonth') || 3),
}

const getters = {
  getCurrentCampaignObject: (state) => state.currentCampaign,
  getSelectedStartingMonth: (state) => state.currentCampaign.startingMonth,
}

const mutations = {
  setCampaignStartingMonth(state, newStartingMonth) {
    localStorage.setItem('campaignStartingMonth', newStartingMonth)
    state.currentCampaign = Campaign.fromDate(new Date(), newStartingMonth)
  },
}

const actions = {}

export default {
  getters,
  actions,
  mutations,
  state,
}
