import ChartLegend from '@/components/MarketSegment/MarketSegmentDataViz/ChartLegend/ChartLegend.vue'

export default {
  name: 'TwoChartsContainer',
  components: {
    ChartLegend,
  },
  props: {
    title: {
      type: String,
    },
    legendChartIds: {
      type: Array,
    },
    isLegendEnabled: {
      type: Boolean,
    },
    isLegendButtonsAreaEnabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    hasSingleChart() {
      return Object.keys(this.$slots).length === 1
    },
  },
  methods: {
    shouldBeFullWidth(chartId) {
      return this.hasSingleChart
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isMounted = true
    })
  },
}
