import ChartFullscreen from '@/components/shared/popins/ChartFullscreen/ChartFullscreen.vue'
import Highcharts from 'highcharts'
import GenericPopin from '@/components/shared/popins/GenericPopin/GenericPopin.vue'
require('highcharts/modules/data')(Highcharts)
require('highcharts/modules/no-data-to-display')(Highcharts)

export default {
  name: 'ChartContainer',
  components: {
    GenericPopin,
    ChartFullscreen,
  },
  props: {
    title: {
      type: String,
    },
    legendChartIds: {
      type: Array,
    },
    isFullscreenLegendEnabled: {
      type: Boolean,
    },
    isFullscreenLegendButtonsAreaEnabled: {
      type: Boolean,
    },
    chartId: {
      type: String,
    },
    chartOptions: {
      type: Object,
    },
    chartData: {
      type: Object,
    },
  },
  data() {
    return {
      chart: undefined,
    }
  },
  computed: {
    enabledPerimeters() {
      return this.$store.getters.getEnabledSeries[this.chartId] || {}
    },
    enabledCampaigns() {
      return this.$store.getters.getEnabledCampaigns || []
    },
    previousCampaign() {
      return this.$store.getters.getCurrentCampaignObject.getPrevious().toString()
    },
    currentCampaign() {
      return this.$store.getters.getCurrentCampaignObject.toString()
    },
  },
  watch: {
    chartData(newChartData) {
      const updatedSeries = newChartData.series.map((serie) => {
        const perimeter = serie.perimeter
        const campaign = serie.campaign
        serie.visible = this.shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign)
        return serie
      })
      if (this.chartOptions && newChartData.yAxis) {
        const updatedYAxis = this.chartOptions.yAxis
        newChartData.yAxis.forEach((yAxis, index) => {
          Object.assign(updatedYAxis[index], yAxis)
        })
        this.chart.update({ series: updatedSeries, yAxis: updatedYAxis })
      } else {
        this.chart.update({ series: updatedSeries })
      }
    },
    chartOptions(newChartOptions) {
      this.chart.update(newChartOptions)
    },
    enabledCampaigns() {
      this.toggleSeriesVisibility()
    },
    enabledPerimeters() {
      this.toggleSeriesVisibility()
    },
  },
  methods: {
    switchFullscreenState() {
      if (this.isFullscreenSelected()) {
        this.$store.commit('setFullscreenChartId', null)
      } else {
        this.$store.commit('setFullscreenChartId', this.chartId)
      }
    },
    isFullscreenSelected() {
      return this.$store.getters.getFullscreenChartId === this.chartId
    },
    toggleSeriesVisibility() {
      this.chart.series.forEach((serie) => {
        if (!this.shouldBeVisible(serie) && serie.visible) serie.hide()
        else if (this.shouldBeVisible(serie) && !serie.visible) serie.show()
      })
      this.chart.yAxis.forEach((axis) => {
        if (!axis.hasVisibleSeries && axis.visible) axis.update({ visible: false })
        else if (axis.hasVisibleSeries && !axis.visible) axis.update({ visible: true })
      })
    },
    shouldBeVisible(serie) {
      const perimeter = serie.options.perimeter
      const campaign = serie.options.campaign
      return this.shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign)
    },
    shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign) {
      if (Object.keys(this.enabledPerimeters).includes(perimeter) && !this.enabledPerimeters[perimeter]) return false
      else if (campaign === 'previous' && !this.enabledCampaigns.includes(this.previousCampaign)) return false
      else if (campaign === 'current' && !this.enabledCampaigns.includes(this.currentCampaign)) return false
      else return true
    },
    closePopin() {
      this.$emit('close-popin')
    },
  },
  async mounted() {
    if (this.chartOptions) {
      const options = this.chartOptions
      if (this.chartData) {
        options.series = []
        this.chartData.series.forEach((serie) => {
          const perimeter = serie.perimeter
          const campaign = serie.campaign
          serie.visible = this.shouldBeVisibleForPerimeterAndCampaign(perimeter, campaign)
          options.series.push(serie)
        })
        if (this.chartData.yAxis) {
          this.chartData.yAxis.forEach((yAxis, index) => {
            Object.assign(options.yAxis[index], yAxis)
          })
        }
      }
      this.chart = Highcharts.chart(this.chartId, options)
      this.$store.commit('upsertChart', this.chart)
    }
  },
}
