import Vue from 'vue'
import Vuex from 'vuex'
import volumeDataModule from './modules/volumeData'
import referentialDataModule from './modules/referentialData'
import chartDisplayDataModule from './modules/chartDisplayData'
import navigationSettingsDataModule from './modules/navigationSettingsData'
import cookiesApprovalDataModule from './modules/cookiesApprovalData'
import campaign from './modules/campaign'
import adminModule from './modules/adminModule'
import { privateApiConnectorService, stringFormatterService, authService } from '@/services'
import router from '@/router'

Vue.use(Vuex)

const state = {
  isStoreInitiated: false,
  authenticated: false,
  connectedSupplierName: '',
  selectedMarketSegmentId: null,
  selectedMarketSegmentName: null,
  selectedMarketSegmentIsSeed: null,
  selectedMarketSegmentSource: '',
  buttonSegmentProductsSelected: false,
  lastUpdateTime: null,
  isDataloaded: false,
  marketSegmentsList: [],
}

export const mutations = {
  setIsStoreInitiated(state, isStoreInitiated) {
    state.isStoreInitiated = isStoreInitiated
  },
  setIsDataLoaded(state, isDataLoaded) {
    state.isDataloaded = isDataLoaded
  },
  setIsAuthenticated(state, isActuallyAuthenticated) {
    state.authenticated = isActuallyAuthenticated
  },
  setSupplierName(state, supplierName) {
    state.connectedSupplierName = supplierName
  },
  setLastUpdateTime(state, lastUpdateTime) {
    state.lastUpdateTime = lastUpdateTime
  },
  setMarketSegmentId(state, marketSegmentId) {
    state.selectedMarketSegmentId = marketSegmentId
  },
  setMarketSegmentName(state, marketSegmentName) {
    state.selectedMarketSegmentName = marketSegmentName
  },
  setMarketSegmentIsSeed(state, marketSegmentIsSeed) {
    state.selectedMarketSegmentIsSeed = marketSegmentIsSeed
  },
  setMarketSegmentSegmentationSource(state, marketSegmentationSource) {
    state.selectedMarketSegmentSource = marketSegmentationSource
  },
  setMarketSegmentsList(state, marketSegmentsList) {
    state.marketSegmentsList = marketSegmentsList
  },
}

export const getters = {
  isAuthenticated: (state) => state.authenticated,
  getSupplierName: (state) => state.connectedSupplierName,
  getLastUpdateTime: (state) => state.lastUpdateTime,
  getSelectedMarketSegmentId: (state) => state.selectedMarketSegmentId,
  getSelectedMarketSegmentName: (state) => state.selectedMarketSegmentName,
  getSelectedMarketSegmentIsSeed: (state) => state.selectedMarketSegmentIsSeed,
  getSelectedMarketSegmentSource: (state) => state.selectedMarketSegmentSource,
  getSelectedMarketSegmentNameFromId: (state) => (id) => {
    if (id === null) return null
    const segmentsWithSameId = state.marketSegmentsList.find((s) => s.id === id)
    if (segmentsWithSameId) return segmentsWithSameId.market_segment
    else return undefined
  },
}

const handleApiResponseErrorStatus = (error) => {
  if (error.response.status === 401) {
    authService.logout()
  } else if (error.response.status === 403) {
    router.replace({ name: 'error' })
  } else if (error.response.status === 503) {
    router.replace({ name: 'unavailable' })
  }
}

export const actions = {
  async initStore({ commit, dispatch, getters }) {
    commit('setIsAuthenticated', true)
    try {
      const fetchMarketSegmentsListPromise = dispatch('fetchMarketSegmentsList')
      const startingMonth = getters.getSelectedStartingMonth
      const fetchSupplierSummaryPromise = dispatch('fetchSupplierSummary', startingMonth)
      const getSupplierName = privateApiConnectorService.getSupplierName()
      const [, , userResult] = await Promise.all([fetchMarketSegmentsListPromise, fetchSupplierSummaryPromise, getSupplierName])
      commit('setSupplierName', userResult.supplier_name)
      dispatch('initAdminData')
      commit('setIsStoreInitiated', true)
    } catch (error) {
      handleApiResponseErrorStatus(error)
    }
  },
  async refreshLastUpdateTime({ commit }) {
    const lastUpdateResult = await privateApiConnectorService.getLastUpdateTime()
    commit('setLastUpdateTime', new Date(lastUpdateResult.updateTime))
  },
  async fetchMarketSegmentsList({ commit }) {
    const marketSegmentsList = await privateApiConnectorService.getMarketSegments()
    commit('setMarketSegmentsList', marketSegmentsList)
  },
  setSelectedMarketSegmentFromSlugified({ commit, dispatch, state, getters }, slugifiedMarketSegmentName) {
    const matchingMarketSegment = state.marketSegmentsList.find(function (element) {
      return stringFormatterService.slugify(element.market_segment) === slugifiedMarketSegmentName
    })

    if (matchingMarketSegment === undefined) {
      router.replace({ name: 'error' })
    }
    commit('setMarketSegmentId', matchingMarketSegment.id)
    commit('setMarketSegmentName', matchingMarketSegment.market_segment)
    commit('setMarketSegmentIsSeed', matchingMarketSegment.is_semence)
    commit('setMarketSegmentSegmentationSource', matchingMarketSegment.market_segmentation_source)
    commit('setCampaignSelections', {
      current: {
        value: getters.getCurrentCampaignObject.toString(),
        label: getters.getCurrentCampaignObject.toString(),
        checked: true,
      },
      previous: {
        value: getters.getCurrentCampaignObject.getPrevious().toString(),
        label: getters.getCurrentCampaignObject.getPrevious().toString(),
        checked: false,
      },
    })
    commit('setFullscreenChartId', null)
    return matchingMarketSegment
  },
  async loadData({ commit, dispatch }, marketSegmentId) {
    commit('setIsDataLoaded', false)
    try {
      const updateVolumePromise = dispatch('updateVolumeData', marketSegmentId)
      const updateReferentialPromise = dispatch('updateReferentialData', marketSegmentId)
      const refreshUpdateTimePromise = dispatch('refreshLastUpdateTime')
      await Promise.all([updateVolumePromise, updateReferentialPromise, refreshUpdateTimePromise])
      commit('setIsDataLoaded', true)
    } catch (error) {
      if (error.response) handleApiResponseErrorStatus(error)
      else throw error
    }
  },
  async updateVolumeDataForNewCampaign({ commit, dispatch, getters }, marketSegmentId) {
    commit('setIsDataLoaded', false)
    try {
      const startingMonth = getters.getSelectedStartingMonth
      await dispatch('updateVolumeData', marketSegmentId)
      await dispatch('fetchSupplierSummary', startingMonth)
      commit('setCampaignSelections', {
        current: {
          value: getters.getCurrentCampaignObject.toString(),
          label: getters.getCurrentCampaignObject.toString(),
          checked: true,
        },
        previous: {
          value: getters.getCurrentCampaignObject.getPrevious().toString(),
          label: getters.getCurrentCampaignObject.getPrevious().toString(),
          checked: false,
        },
      })
      commit('setIsDataLoaded', true)
    } catch (error) {
      handleApiResponseErrorStatus(error)
    }
  },
  resetMarketSegmentSelection({ commit }) {
    commit('setMarketSegmentId', null)
    commit('setMarketSegmentName', null)
  },
}

export default new Vuex.Store({
  getters,
  actions,
  mutations,
  state,
  modules: {
    volumeDataModule,
    referentialDataModule,
    chartDisplayDataModule,
    navigationSettingsDataModule,
    cookiesApprovalDataModule,
    campaign,
    adminModule,
  },
})
