export default {
  name: 'ChartLegend',
  props: ['chartIds', 'withTwoLevelsLegend'],
  computed: {
    charts() {
      return this.$store.getters.getCharts
    },
    enabledSeries() {
      const result = {}
      this.chartIds.forEach((chartId) => {
        Object.assign(result, this.$store.getters.getEnabledSeries[chartId])
      })
      return result
    },
    seriesByPerimeter() {
      const flattenSeries = []
      this.chartIds
        .map((id) => this.charts[id] || { series: [] })
        .forEach((chart) => chart.series.forEach((serie) => flattenSeries.push(serie)))
      return flattenSeries.reduce(function (seriesByPerimeter, serie) {
        ;(seriesByPerimeter[serie.options.perimeter] = seriesByPerimeter[serie.options.perimeter] || []).push(serie)
        return seriesByPerimeter
      }, {})
    },
    chartsByPerimeter() {
      const result = {}
      Object.keys(this.seriesByPerimeter).forEach((perimeter) => {
        const chartIds = this.seriesByPerimeter[perimeter].map((serie) => serie.chart.renderTo.id)
        const chartIdsUniqueValues = [...new Set(chartIds)]
        result[perimeter] = chartIdsUniqueValues
      })
      return result
    },
    perimeters() {
      return Object.keys(this.seriesByPerimeter).map((perimeter) => {
        return {
          label: this.getPerimeterFullName(perimeter),
          color: this.getPerimeterColor(perimeter),
          checked: this.isVisible(perimeter),
          value: perimeter,
        }
      })
    },
  },
  methods: {
    getPerimeterFullName(perimeter) {
      return this.$store.getters.getFullNamePerimeter(perimeter)
    },
    changeLegendSelection(perimeter) {
      const mustHide = this.isVisible(perimeter)
      this.chartsByPerimeter[perimeter].forEach((chartId) => {
        const payload = { chartId, perimeter }
        if (mustHide) this.$store.commit('disableSerieByPerimeter', payload)
        else this.$store.commit('enableSerieByPerimeter', payload)
      })
    },
    unselectLegendItems() {
      Object.keys(this.seriesByPerimeter).forEach((perimeter) => {
        this.chartsByPerimeter[perimeter].forEach((chartId) => {
          this.$store.commit('disableSerieByPerimeter', { chartId, perimeter })
        })
      })
    },
    isVisible(perimeter) {
      return this.enabledSeries[perimeter] === undefined || this.enabledSeries[perimeter]
    },
    getPerimeterColor(perimeter) {
      return this.seriesByPerimeter[perimeter][0].color
    },
    setPerimetersColor() {
      this.perimeters.forEach((perimeter) => {
        const checkbox = this.$refs[perimeter.value]
        if (checkbox && !perimeter.checked) {
          checkbox[0].$el.querySelector('.v-icon').style = 'color: ' + perimeter.color + '; caret-color: ' + perimeter.color
        }
      })
    },
  },
  mounted() {
    this.setPerimetersColor()
  },
  updated() {
    this.setPerimetersColor()
  },
}
